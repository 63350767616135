import React, {useState, useEffect} from 'react';
import Card from '@material-ui/core/Card';
import Label from '../../../../components/src/DesignSystem/Label/Label.web';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CustomTypography from '../CustomTypography/CustomTypography.web';
import { checkedIcon, uncheckedIcon } from './assets';

const PasswordStrength = (props: any) => {

    const [number, setNumber] = useState(false);
    const [pwdLength, setPwdLength] = useState(false);
    const [lowCase, setLowCase] = useState(false);
    const [upCase, setUpCase] = useState(false);
    const [pwdMatch, setPwdMatch] = useState(false);
    const [boxLength, setBoxLength] = useState(1);
    const checkStrength = () => {
        const passLen = props.password.length >= 8
        const passHaveNums = /[0-9]/.test(props.password)
        const passHaveLowerCase = /[a-z]/.test(props.password)
        const passHaveUpperCase = /[A-Z]/.test(props.password)

        setPwdLength(passLen) 
        setNumber(passHaveNums)
        setLowCase(passHaveLowerCase)
        setUpCase(passHaveUpperCase)  
        const tempArr = [passLen, passHaveNums, passHaveLowerCase, passHaveUpperCase]
        const trueCount: any = tempArr.reduce((count: any, current: any) => {
            if (current) {
              return count + 1;
            }
            return count;
        }, 0);
        setBoxLength(trueCount)     
    }

    const getBoxes = () => {
        if(boxLength == 1){
            return(
                <div style={styles.boxWrap}>
                    <div style={styles.yellowBox1}></div>
                </div>
            )
        } else if(boxLength == 2){
            return(
                <div style={styles.boxWrap}>
                    <div style={styles.yellowBox1}></div>
                    <div style={styles.yellowBox}></div>
                </div>
            )
        } else if(boxLength == 3){
            return(
                <div style={styles.boxWrap}>
                    <div style={styles.yellowBox1}></div>
                    <div style={styles.yellowBox}></div>
                    <div style={styles.yellowBox}></div>
                </div>
            )
        } else {
            return(
                <div style={styles.boxWrap}>
                    <div style={styles.greenBox1}></div>
                    <div style={styles.greenBox}></div>
                    <div style={styles.greenBox}></div>
                    <div style={styles.greenBox}></div>
                </div>
            )
        }
    }

    useEffect(() => {
            checkStrength();
        if(props.confirmPwd){
            if(props.password === props.confirmPwd){
                setPwdMatch(true)
            } else {
                setPwdMatch(false)
            }
        }
    },[props.password,props.confirmPwd])

    const getPrimary = () => {
        return (
            <div>
                {
                    getBoxes()
                }
                
                {boxLength < 4 ? 
                    <div style={styles.cardWrap}>
                        <Card
                            style={styles.card}
                        >
                            <div>
                                <Label
                                    variant='primary'
                                    weight='w400'
                                    textTransform="none"
                                    size='xl'
                                >
                                    Your password should 
                                </Label>
                            </div>
                            <div
                                style={styles.ruleList}
                            >
                                {
                                    pwdLength ? 
                                        <CheckCircleOutlineIcon style={styles.listIconGreen} />
                                    :   <RemoveCircleOutlineIcon style={styles.listIconRed} />
                                }
                                <span
                                    style={styles.listText}
                                >
                                    <Label
                                        variant='secondary'
                                        weight='w400'
                                        textTransform="none"
                                        size='l'
                                    >
                                        Minimum 8 characters
                                    </Label>
                                </span>
                            </div>
                            <div
                                style={styles.ruleList}
                            >
                                {
                                    upCase ? 
                                        <CheckCircleOutlineIcon style={styles.listIconGreen} />
                                    :   <RemoveCircleOutlineIcon style={styles.listIconRed} />
                                }
                                <span
                                    style={styles.listText}
                                >
                                    <Label
                                        variant='secondary'
                                        weight='w400'
                                        textTransform="none"
                                        size='l'
                                    >
                                        Contains uppercase characters ( A, C , E )
                                    </Label>
                                </span>
                            </div>
                            <div
                                style={styles.ruleList}
                            >
                                {
                                    lowCase ? 
                                        <CheckCircleOutlineIcon style={styles.listIconGreen} />
                                    :   <RemoveCircleOutlineIcon style={styles.listIconRed} />
                                }
                                <span
                                    style={styles.listText}
                                >
                                    <Label
                                        variant='secondary'
                                        weight='w400'
                                        textTransform="none"
                                        size='l'
                                    >
                                        Contains lowercase characters ( a, c , e )
                                    </Label>
                                </span>
                            </div>
                            <div
                                style={styles.ruleList}
                            >
                                {
                                    number ?
                                        <CheckCircleOutlineIcon style={styles.listIconGreen} />
                                    :   <RemoveCircleOutlineIcon style={styles.listIconRed} />
                                }
                                <span
                                    style={styles.listText}
                                >
                                    <Label
                                        variant='secondary'
                                        weight='w400'
                                        textTransform="none"
                                        size='l'
                                    >
                                        Contains number ( 0, 1, 9)
                                    </Label>
                                </span>
                            </div>
                        </Card>
                    </div>
                : null}
            </div>
        )
    }

    const getSecondary = (hideMatchPass:any) => {
        return(
            <div
                style={styles.cardWrap}
            >
                <div
                    style={styles.ruleList}
                >
                    <div style={styles.alignSelfCenter}>
                        {
                            pwdLength ?
                                <img 
                                    src={checkedIcon}
                                    style={styles.icon}
                                />
                            :   <img 
                                    src={uncheckedIcon}
                                    style={styles.icon}
                                />
                        }
                    </div>
                    <div
                        style={styles.ml10}
                    >
                        <CustomTypography
                            variant={'primary'}
                            component={'body12'}
                        >
                            Minimum 8 characters
                        </CustomTypography>
                    </div>
                </div>
                <div
                    style={styles.ruleList}
                >
                    <div style={styles.alignSelfCenter}>
                        {
                            upCase ?
                                <img 
                                    src={checkedIcon}
                                    style={styles.icon}
                                />
                            :   <img 
                                    src={uncheckedIcon}
                                    style={styles.icon}
                                />
                        }
                    </div>
                    <div
                        style={styles.ml10}
                    >
                        <CustomTypography
                            variant={'primary'}
                            component={'body12'}
                        >
                            Contain uppercase characters ( A, C , E )
                        </CustomTypography>
                    </div>
                </div>
                <div
                    style={styles.ruleList}
                >
                    <div style={styles.alignSelfCenter}>
                        {
                            lowCase ?
                                <img 
                                    src={checkedIcon}
                                    style={styles.icon}
                                />
                            :   <img 
                                    src={uncheckedIcon}
                                    style={styles.icon}
                                />
                        }
                    </div>
                    <div
                        style={styles.ml10}
                    >
                        <CustomTypography
                            variant={'primary'}
                            component={'body12'}
                        >
                            Contain lowercase characters ( a, c , e )
                        </CustomTypography>
                    </div>
                </div>
                <div
                    style={styles.ruleList}
                >
                    <div style={styles.alignSelfCenter}>
                        {
                            number ?
                                <img 
                                    src={checkedIcon}
                                    style={styles.icon}
                                />
                            :   <img 
                                    src={uncheckedIcon}
                                    style={styles.icon}
                                />
                        }
                    </div>
                    <div
                        style={styles.ml10}
                    >
                        <CustomTypography
                            variant={'primary'}
                            component={'body12'}
                        >
                            Contains number ( 0, 1, 9)
                        </CustomTypography>
                    </div>
                </div>
               {!!!hideMatchPass && <div
                    style={styles.ruleList}
                >
                    <div style={styles.alignSelfCenter}>
                        {
                            pwdMatch ?
                                <img 
                                    src={checkedIcon}
                                    style={styles.icon}
                                />
                            :   <img 
                                    src={uncheckedIcon}
                                    style={styles.icon}
                                />
                        }
                    </div>
                    <div
                        style={styles.ml10}
                    >
                        <CustomTypography
                            variant={'primary'}
                            component={'body12'}
                        >
                            Password match
                        </CustomTypography>
                    </div>
                </div>}
            </div>
        )
    }

    return  (
        props.variant == 'primary' ?
            getPrimary()
        :
            getSecondary(props.hideMatchPass)
    )
}

const styles: any = {
    cardWrap: {
        width: '100%',
        marginTop: '10px'
    },
    card: {
        borderRadius: '0px',
        padding: '15px',
        paddingTop: '20px',
        paddingBottom: '20px'
    },
    ruleList: {
        display: 'flex',
        marginTop: '10px',
        marginBottom: '10px'
    },
    listIconGreen: {
        height: '12px',
        width: '12px',
        color: 'green'
    },
    listIconRed: {
        height: '12px',
        width: '12px',
        color: 'red'
    },
    listText: {
        marginLeft: '5px'
    },
    boxWrap: {
        display: 'flex'
    },
    yellowBox: {
        height: '3px',
        width: '51px',
        background: '#F59E0B',
        marginLeft: '5px'
    },
    yellowBox1: {
        height: '3px',
        width: '51px',
        background: '#F59E0B'
    },
    greenBox: {
        height: '3px',
        width: '51px',
        background: '#057A55',
        marginLeft: '5px'
    },
    greenBox1: {
        height: '3px',
        width: '51px',
        background: '#057A55'
    },
    icon: {
        height: '16px',
        width: '16px'
    },
    ml10: {
        marginLeft: '10px'
    },
    alignSelfCenter:{
        alignSelf:"center"
    }
}

export default PasswordStrength;