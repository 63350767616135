Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CarouselDisplay";
exports.labelBodyText = "CarouselDisplay Body";
exports.btnExampleTitle = "CLICK ME";
exports.title = "Qstock AI Policy and Account Disabling";
exports.subTitle = "Our marketplace policy does not currently allow content generated by AI to be directly uploaded and sold in our marketplace to protect contributors IP ownership of all content that is submitted. Submitting Ai work will lead to your account being disabled.";
exports.button = "learn more";
// Customizable Area End