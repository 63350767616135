import React from "react"

interface Props{
    color?:string,
}

const AccountIcon =(props:Props)=>(
    <>
    <svg width="24" height="24"  fill={props.color??"black"} xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5 6.4C13.66 6.4 14.6 7.34 14.6 8.5C14.6 9.66 13.66 10.6 12.5 10.6C11.34 10.6 10.4 9.66 10.4 8.5C10.4 7.34 11.34 6.4 12.5 6.4ZM12.5 15.4C15.47 15.4 18.6 16.86 18.6 17.5V18.6H6.4V17.5C6.4 16.86 9.53 15.4 12.5 15.4ZM12.5 4.5C10.29 4.5 8.5 6.29 8.5 8.5C8.5 10.71 10.29 12.5 12.5 12.5C14.71 12.5 16.5 10.71 16.5 8.5C16.5 6.29 14.71 4.5 12.5 4.5ZM12.5 13.5C9.83 13.5 4.5 14.84 4.5 17.5V20.5H20.5V17.5C20.5 14.84 15.17 13.5 12.5 13.5Z" />
    </svg>
    </>

)

export default AccountIcon