Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "NavigationMenu";
exports.labelBodyText = "NavigationMenu Body";

exports.btnExampleTitle = "CLICK ME";

exports.openDrawerText = "Open Drawer";
exports.userName = "Jordan Ramsay";
exports.userDesignation = "Miami Herica";
exports.logout = "Logout";
exports.stockText = "by stock photos & videos";
exports.jsonApiContentType = "application/json";
exports.getMenuItemsEndPoint = "navmenu/navigation_menu";
exports.getApiMethodType = "GET";
exports.assestType =[
 {label:"English",value:'en'},
  {label:"Arabic",value:"ar"},
  {label:"Persian",value:"pr"},
  {label:"Hebrew",value:"hb"},
  {label:"Turkish",value:"tr"}];
  exports.profileType =[
    {label:"Account Settings",value:'as'},
     {label:"My Portfolio",value:"mp"},
     {label:"Support Center",value:"sc"},
     {label:"Contact Us",value:"cu"},
     {label:"Log Out",value:"lg"}];
     exports.logoutConfirmationText ="Are you sure you want to Logout?";
     exports.cancel="Cancel";
    exports.yes="Yes";
exports.title = "ARE YOU SURE WANT TO LOGOUT?";
exports.subTitle = "Logging out will end your current session and you'll need to sign in again to access your account.";
exports.button = "Yes, LOGOUT";
exports.button1 = "cancel";
// Customizable Area End