import React from "react";
// Customizable Area Start
import SortIcon from "@material-ui/icons/Sort";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import { createStyles, withStyles, Theme } from "@material-ui/core";

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
        minWidth: '120px',
        outline: 'none',
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      position: 'relative',
      backgroundColor: 'transparent',
      border: 'none',
      fontSize: '12px',
      outline: 'none',
      padding: '10px 26px 10px 12px',
      '&:focus': {
        borderRadius: '0',
        border: 'none',
        outline: 'none',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }),
)(InputBase);
// Customizable Area End

import SortingController, {
  Props
  //configJSON
} from "./SortingController";

export default class Sorting extends SortingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      selectedOption
    } = this.state;
    return (
      <div>
        <div
          style={webStyles.sortWrap}
        >
          <div
            style={webStyles.sortIcon}
          >
            <SortIcon />
          </div>
          <div>
            <FormControl>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={selectedOption}
                onChange={(e: any) => this.handleChange(e)}
                input={<BootstrapInput />}
              >
                  <MenuItem value={'all'}>SORT BY</MenuItem>
                  <MenuItem value={'most_relevant'}>MOST RELEVANT</MenuItem>
                  <MenuItem value={'popular'}>POPULAR</MenuItem>
                  <MenuItem value={'fresh_content'}>FRESH CONTENT</MenuItem>
                  <MenuItem value={'random'}>RANDOM</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles: any = {
  sortWrap: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  selectSort: {
    border: 'none'
  },
  sortIconsWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  sortIcon: {
    marginTop: '5px'
  }
}
// Customizable Area End
