export const imageUpload = require("../assets/imageUpload.png");
export const galleryIcon = require("../assets/galleryIcon.png");
export const premiumRound = require("../assets/premiumRound.png");
export const uploadImageIcon = require("../assets/uploadIcon.png")
export const leftCropIcon = require("../assets/left_icon.svg")
export const RightCropIcon = require("../assets/right_crop.svg")
export const watermark = require("../assets/watermark.png")
export const CropWatermark = require("../assets/crop_watermark.png")





