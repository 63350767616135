import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
interface IContent {
  id: number;
  title: string;
  description: string;
  nsfw_content: boolean;
  technical_specification: string;
  category_id: number | null;
  location: string;
  keyword_array: string[],
  price: number | null;
  date: Date;
  status: string;
  file_link: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  contentId: string;
  selectedTab:number;
  selectedSortType: { value: string | number; label: string };
  contentDetails: IContent | null;
  titleText: string
  descriptionText: string
  specificationText: string
  filterKey: string;
  locationSearchQuery: string;
  searchList: string[];
  categories: { id: number, title: string }[];
  showDeleteDialog:boolean;
  location:any;
  locationData:any;
  suggestionLocation:any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ReviewSetPriceModeratorController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getContentDetailsApiCallId: any;
  getCategoriesApiCallId: any;
  deleteContentApiCallId: string = "";
  showAllLOcationCallId : string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      contentId: this.props.navigation.getParam('id'),
      token: '',
      selectedTab:0,
      categories: [],
      selectedSortType:configJSON.sortBy[0],
      contentDetails: null,
      filterKey: '',
      titleText: '',
      descriptionText: '',
      specificationText: '',
      locationSearchQuery: '',
      searchList: [],
      showDeleteDialog:false,
      location:"",
      locationData:[],
      suggestionLocation:[]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message)

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = localStorage.getItem('authToken') || '';
      runEngine.debugLog("TOKEN", token);
      if (token) {
        this.setState({token});
      }
      this.getContentDetails(this.state.contentId, token)
    }
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.getContentDetailsApiCallId:
        this.setContentDetails(responseJson)
        break;
      case this.getCategoriesApiCallId:
        this.setCategories(responseJson)
        break;
        case this.deleteContentApiCallId:
          this.setDeletedContentCall()
          break;
          case this.showAllLOcationCallId:
            this.setState({locationData: responseJson})
            break;
      default:
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getCategories();
    this.showAllLocation()
  }
  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  setCategories = (response:any) => {
    const list = response.data.map((category:any) => {
      return ({
        id: category.attributes.id,
        title: category.attributes.title
      })
    })
    this.setState({ categories: list})
  }

  setContentDetails = (response:any) => {
    const { title, description, technical_specification, nsfw_content, category_id, location, keyword_array, price, file_link, status, date } = response.data.attributes?.file_details || {}
    const details:IContent = {
      id: response.data.id,
      title: title || '',
      description: description || '',
      technical_specification: technical_specification || '',
      nsfw_content: nsfw_content || false,
      category_id: category_id || null,
      location: location || '',
      keyword_array: keyword_array || [],
      price: price || null,
      file_link: file_link,
      status: status,
      date: date,
    }
    console.log('details',details)

    this.setState({ contentDetails: details})
  }

  getContentDetails = (id:string, token: string) => {
    const header = {
      "Content-Type": configJSON.contentApiContentType,
      token: token
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getContentDetailsApiCallId = requestMessage.messageId;
    let endPoint = `${configJSON.contentDetailsApiEndPoint}/${id}`
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
   
    runEngine.sendMessage(requestMessage.id, requestMessage)

  }

  getCategories = () => {
    const header = {
      "Content-Type": configJSON.contentApiContentType,
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getCategoriesApiCallId = requestMessage.messageId;
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
   
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  handleTitleChange = (e: any) => {
    this.setState({ titleText: e.value});
  };

  handleDescriptionChange = (e: any) => {
    const newValue =  e.target.value.slice(0, 500)
    this.setState({ descriptionText: newValue});
  };

  handleSpecificationChange = (e: any) => {
    this.setState({ specificationText: e.value});
  };

  handleChangeSortType = (option: {
    value: string | number;
    label: string;
  }) => {
    this.setState({ selectedSortType: option });
  };
  locationSearchChange = (value: any) => {
    this.setState({locationSearchQuery: value})
    const header = {
        "Content-Type": configJSON.advancedsearchApiContentType
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getRecommendationApiEndPoint+value
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  deletecontent = (deleteID:any) => {
    this.setState({showDeleteDialog: true})
  }
  handleCloseDeleteDialog  = () => {
    this.setState({showDeleteDialog: false})
  }
  handleDeleteContent = () => {
    this.setState({showDeleteDialog: false})
    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.deleteContentApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteContentEndPoint.replace(
        ":id",
        this.state.contentDetails?.id
      )
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  setDeletedContentCall = () => {
    const goToContentApproval: Message = new Message(getName(MessageEnum.NavigationMessage));
    goToContentApproval.addData(getName(MessageEnum.NavigationTargetMessage), "ReviewApprovalModerator");
    goToContentApproval.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(goToContentApproval);
  }
  showAllLocation = async () =>{
    const header = {
      token: this.state.token,
    };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.showAllLOcationCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.allCountryEndPoints
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.apiMethodTypeGet
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
      return this.showAllLOcationCallId;
}
handleInputLocationChange = (event: any) => {
  const location = event.value;
  this.setState({ location }, () => {
    this.filterSuggestions();
  });
};

filterSuggestions = () => {
  const { location, locationData } = this.state;
  const filteredSuggestions = locationData?.filter((suggestion: any) =>
    suggestion.toLowerCase().includes(location.toLowerCase()),
  );
  this.setState({ suggestionLocation: filteredSuggestions });
};

handleSuggestionClick = (suggestion: any) => {
  this.setState({ location: suggestion, suggestionLocation: [] });
};
  
  // Customizable Area End
}
