Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Watermark";
exports.labelBodyText = "Watermark Body";
exports.watermarkImageCondition="Upload your watermark with no background "
exports.selectFiles="Choose file";
exports.dragContent="Drag and drop your files here";
exports.or="or"
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End