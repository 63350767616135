import React from "react"


interface Props{
    color?:string,
}


const EarningIcon =(props:Props)=>(
    <>
    <svg width="24" height="24" viewBox="0 0 25 24" fill={props.color ?? "black"} xmlns="http://www.w3.org/2000/svg">
<g id="group_icon">
<path fill ="inherit" id="view_vector" d="M12.5 2C6.98 2 2.5 6.48 2.5 12C2.5 17.52 6.98 22 12.5 22C18.02 22 22.5 17.52 22.5 12C22.5 6.48 18.02 2 12.5 2ZM12.5 20C8.09 20 4.5 16.41 4.5 12C4.5 7.59 8.09 4 12.5 4C16.91 4 20.5 7.59 20.5 12C20.5 16.41 16.91 20 12.5 20ZM12.81 11.14C11.04 10.69 10.47 10.2 10.47 9.47C10.47 8.63 11.26 8.04 12.57 8.04C13.95 8.04 14.47 8.7 14.51 9.68H16.22C16.17 8.34 15.35 7.11 13.73 6.71V5H11.4V6.69C9.89 7.01 8.68 7.99 8.68 9.5C8.68 11.29 10.17 12.19 12.34 12.71C14.29 13.17 14.68 13.86 14.68 14.58C14.68 15.11 14.29 15.97 12.58 15.97C10.98 15.97 10.35 15.25 10.26 14.33H8.54C8.64 16.03 9.9 16.99 11.4 17.3V19H13.74V17.33C15.26 17.04 16.46 16.17 16.47 14.56C16.46 12.36 14.57 11.6 12.81 11.14Z" />
</g>
</svg>

    </>
)

export default EarningIcon