import React from "react";

// Customizable Area Start
import {
  Box
} from "@material-ui/core";
import Header from "../../../components/src/DesignSystem/Header/Header.web";
import CustomTypography from "../../../components/src/DesignSystem/CustomTypography/CustomTypography.web";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box>
        <Header testID={""} navigation={this.props.navigation} classNameProps={'whiteHeader'} />
        <Box pl={10} pr={10} mt={5}>
            {
              this.state.termsConds && this.state.termsConds.map((item: any, index: any) => {
                  return (
                      <Box>
                          <CustomTypography
                              variant={'primary'}
                              component={'h4'}
                          >
                              {index+1}. {item.title}
                          </CustomTypography>
                          <Box mt={1}>
                              <div dangerouslySetInnerHTML={{ __html: item.description }}/>
                          </Box>
                      </Box>
                  )
              })
            }
        </Box>
      </Box>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
