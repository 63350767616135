import React from "react";
// Customizable Area Start
// Customizable Area End

import EmailAccountLogoutController, {
  Props,
  configJSON
} from "./EmailAccountLogoutController";
import { Button, styled } from "@material-ui/core";
import ModalComponent from "../../../components/src/DesignSystem/Modalcomponent/ModalComponent.web";
import CustomButton from "../../../components/src/DesignSystem/CustomButton/CustomButton.web";
import CustomTypography from "../../../components/src/DesignSystem/CustomTypography/CustomTypography.web";
import Divider from "../../../components/src/DesignSystem/Divider/Divider.web";

export default class EmailAccountLogoutContributor extends EmailAccountLogoutController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <>
        <Button
          data-test-id="handleLogout"
          onClick={() => this.handleModal(this.state.open)}
          style={this.props.style}
        >
          LOGOUT
        </Button>
        <ModalComponent open={this.state.open} maxWidth={541} maxHeight={300}>
          <LogoutParentC>
            <CustomTypograpyParentC>
              <CustomTypography
                variant={"primary"}
                component={"body6"}
                textTransform={"uppercase"}
              >
                {configJSON.title}
              </CustomTypography>
            </CustomTypograpyParentC>
            <Divider variant="whiteDark"></Divider>
            <ParentDivC>
              <CustomTypograpyParent1C>
                <CustomTypography variant={"secondary"} component={"body10"}>
                {configJSON.subTitle}
                </CustomTypography>
              </CustomTypograpyParent1C>
              <LogoutDivC>
                <LogoutDivInnerC>
                  <CustomButton
                    onClick={() => this.handleModal(this.state.open)}
                    variant="secondary"
                    fullWidth="fullWidth"
                    data-test-id="handleOpenDelete1"
                    size={"medium"}
                  >
                   {configJSON.button1}
                  </CustomButton>
                </LogoutDivInnerC>
                <LogoutDivInnerC>
                  <CustomButton
                    variant="red"
                    fullWidth="fullWidth"
                    data-test-id="handleContributorLogout"
                    size={"medium"}
                    onClick={() => this.handleContributorLogout()}
                  >
                   {configJSON.button}
                  </CustomButton>
                </LogoutDivInnerC>
              </LogoutDivC>
            </ParentDivC>
          </LogoutParentC>
        </ModalComponent>
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

const ParentDivC = styled("div")({
  padding: "0px 32px",
});

const LogoutParentC = styled("div")({
  padding: "12px",
});

const CustomTypograpyParentC = styled("div")({
  display: "flex",
  justifyContent: "center",
});

const CustomTypograpyParent1C = styled("div")({
  textAlign: "center",
  padding: "32px 0px",
  "@media(max-width:600px)": {
    "& br": {
      display: "none",
    },
  },
});

const LogoutDivC = styled("div")({
  display: "flex",
  gap: "24px",
});

const LogoutDivInnerC = styled("div")({
  width: "100%",
});

// Customizable Area End