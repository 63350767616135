import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

const StyledCheckbox = withStyles({
  root: {
    color: 'black',
    borderRadius: '0px',
    '&$checked': {
      color: 'black', 
      borderRadius: '0px',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const CustomCheckBox = (props: any) => {

  return(
      <StyledCheckbox 
        {...props} 
        checked={props.checked}
        onChange={props.onChange}
      />
  )
}

export default CustomCheckBox;