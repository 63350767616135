import React from "react"

interface Props{
    color?:string,
}


const PortfolioIcon =(props:Props)=>(
    <>
    <svg width="24" height="24"  viewBox="0 0 25 24" fill={props.color?? "black"} xmlns="http://www.w3.org/2000/svg">
    <g id="group_icon">
    <path id="view_vector" fill-rule="evenodd" clip-rule="evenodd" d="M14.3182 2C14.8004 2 15.2629 2.19156 15.6038 2.53253C15.9448 2.87351 16.1364 3.33597 16.1364 3.81818V4.72727H19.7727C20.496 4.72727 21.1897 5.01461 21.7012 5.52607C22.2127 6.03754 22.5 6.73123 22.5 7.45455V19.2727C22.5 19.996 22.2127 20.6897 21.7012 21.2012C21.1897 21.7127 20.496 22 19.7727 22H5.22727C4.50396 22 3.81026 21.7127 3.2988 21.2012C2.78734 20.6897 2.5 19.996 2.5 19.2727V7.45455C2.5 6.73123 2.78734 6.03754 3.2988 5.52607C3.81026 5.01461 4.50396 4.72727 5.22727 4.72727H8.86364V3.81818C8.86364 3.33597 9.05519 2.87351 9.39617 2.53253C9.73714 2.19156 10.1996 2 10.6818 2H14.3182ZM20.6818 12.9091H4.31818V19.2727C4.31657 19.4961 4.39723 19.7122 4.54478 19.8799C4.69233 20.0475 4.89645 20.155 5.11818 20.1818H19.7727C20.0138 20.1818 20.2451 20.086 20.4156 19.9156C20.586 19.7451 20.6818 19.5138 20.6818 19.2727V12.9091ZM19.7727 6.54545H5.22727C4.98617 6.54545 4.75494 6.64123 4.58445 6.81172C4.41396 6.98221 4.31818 7.21344 4.31818 7.45455V11.0909H20.6818V7.45455C20.6834 7.2312 20.6028 7.01508 20.4552 6.84741C20.3077 6.67974 20.1036 6.57225 19.8818 6.54545H19.7727ZM14.3182 3.81818H10.6818V4.72727H14.3182V3.81818Z" />
    </g>
    </svg>
    </>
    
)

export default PortfolioIcon