import React from "react";

// Customizable Area Start
import {
  Grid, Popover, MenuList, MenuItem,styled
} from "@material-ui/core";
export const configJSON = require("./config");
export const images = require('./assets')
import CustomTypography from "../../../components/src/DesignSystem/CustomTypography/CustomTypography.web";
import CustomButton from "../../../components/src/DesignSystem/CustomButton/CustomButton.web";
import PhotoLibraryCollectionController, { Props } from "./PhotoLibraryCollectionController.web";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import customTheme from "../../../components/src/DesignSystem/Theme/Theme.web";
import { VisibilityOff } from "@material-ui/icons";
import Input from "../../../components/src/DesignSystem/Input/Input.web";
import CustomSnackBar from "../../../components/src/DesignSystem/CustomSnackBar/CustomSnackBar";
import Divider from "../../../components/src/DesignSystem/Divider/Divider.web";
import ModalComponent from "../../../components/src/DesignSystem/Modalcomponent/ModalComponent.web";


// Customizable Area End


// Customizable Area Start
// Customizable Area End

export default class PhotoLibraryGallery extends PhotoLibraryCollectionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getCollectionType = (collectionSubtype: any) => {
    return collectionSubtype === "public_domain" ? 'Published collection' : 'Private collection';
  };
  getPublishStatus = (collectionSubtype: any) => {
    return collectionSubtype === "public_domain" ? (
      <><VisibilityOff style={webStyle.iconSizes} /> Unpublish</>
    ) : (
      <><img src={images.eyeIcon} style={webStyle.iconSizes} /> Publish</>
    );
  };
  renderCollectionName(attributes: any) {
    const { collectionID, editing, collectionName } = this.state;
    return (
      <>
        {attributes.id == collectionID && editing ? (
          <Input
            value={collectionName ? collectionName : attributes.collection_name}
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            autoFocus
            data-test-id="handleChangeName"
          />
        )
          : (
            <CustomTypography variant="body2" component="blue">{attributes.collection_name}</CustomTypography>

          )}
      </>
    )
  }
  showContent = (contentType:string, urls:string) => {
    return (
      <>
      {contentType == "image" ? <img src={urls} style={webStyle.showImage} /> : <video src={urls} style={webStyle.showImage} />}
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { openCollectionModal, showCollectionData, collectionData, prevPage, nextPage, currentPage, totalCount, selectedContent } = this.props;
    const { showAlert, userSuccessAlert } = this.state;
    const contentType = selectedContent.value;
    return (
      <Grid>
        <Grid container spacing={2}>
          {collectionData?.length > 0 ?
            <Grid container spacing={2} style={webStyle.mainShowContainer}>
              {collectionData?.map((collectionData: any, index: any) => {
                const { attributes } = collectionData;
                const galleriesCount = totalCount.count?.find((count: any) => count.id === attributes.id)?.galleries_count || "00";
                const imageUrls = totalCount.collections?.included
                  .filter((gallery: any) => gallery.attributes.collection_attributes.id === attributes.id)
                  .map((gallery: any) =>
                    gallery.attributes.catalogues.map((catalogue: any) => catalogue.images.url)
                  )
                  .flat();
                return (
                  <Grid style={webStyle.showCollectionData} key={index}>
                    <Grid onClick={() => showCollectionData(collectionData)} data-test-id="showCollectionData" style={webStyle.collectionContent}>
                      {imageUrls?.length > 0 ?
                        <Grid style={{
                          ...webStyle.imageSet,
                          gridTemplateColumns: `repeat(${galleriesCount / 2}, 1fr)`
                        }}>
                          {imageUrls.map((urls: any) => {
                            return (
                              this.showContent(contentType, urls)
                            )
                          })}
                        </Grid>
                        :
                        <img src={images.placeholderImage} style={webStyle.showImage} />
                      }
                    </Grid>
                    <Grid style={webStyle.sortStyle}>
                      {this.renderCollectionName(attributes)}
                      <Grid style={webStyle.sortDetails}>
                        <Grid style={webStyle.contentDetail}>
                          <img src={images.fileIcon} style={webStyle.iconSize} />
                          <CustomTypography variant="outfitBody2" component="text_capitalize">{galleriesCount} {attributes.collection_type}</CustomTypography>
                        </Grid>
                        <Grid style={webStyle.contentDetail}>
                          <img src={images.eyeIcon} style={webStyle.iconSize} />
                          <CustomTypography component="outfitBody2">{this.getCollectionType(attributes.collection_subtype)}</CustomTypography>
                        </Grid>
                        <Grid style={webStyle.contentDetail}>
                          <img src={images.moreIcon} onClick={(e: any) => {
                            this.handleIconClick(e, collectionData);
                          }} style={{ ...webStyle.iconSize, ...webStyle.pointer }} data-test-id="handleIconClick" />
                        </Grid>
                        {attributes.id == this.state.collectionID && (
                          <Popover
                            open={Boolean(this.state.anchorEl)}
                            anchorEl={this.state.anchorEl}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            PaperProps={{
                              style: {
                                boxShadow: `${customTheme.palette.shadow.main} 0px 2px 1px`,
                              },
                            }}
                            onClose={() => this.setState({ anchorEl: null })}
                            data-test-id="close"
                          >
                            <MenuList>
                              <MenuItem
                                data-test-id="editUserPage"
                                style={webStyle.selectedOption}
                                onClick={() => this.handleEdit()}
                              >
                                <img src={images.editIcon} style={webStyle.iconSizes} /> Rename
                              </MenuItem>
                              <MenuItem
                                style={webStyle.selectedOption}
                                onClick={this.changeStatusApi}
                                data-test-id="changeStatusApi"
                              >
                                {this.getPublishStatus(attributes.collection_subtype)}
                              </MenuItem>
                              <MenuItem
                                style={webStyle.selectedOption}
                                onClick={this.deleteMdal}
                                data-test-id="deleteCollectionApi"
                              >
                                <img src={images.deleteIcon} style={webStyle.iconSizes} />  Delete
                              </MenuItem>
                            </MenuList>
                          </Popover>)}
                      </Grid>
                    </Grid>
                  </Grid>
                )

              })}
              {totalCount?.total_pages > 1 && <Grid style={webStyle.paginationContainer}>
                <div style={webStyle.pageBtn} onClick={prevPage}>
                  <ChevronLeftIcon style={this.getIconLeftStyle(webStyle)} />
                </div>
                <CustomButton variant="primary">{currentPage}</CustomButton>
                <Grid style={webStyle.pageBtn} onClick={nextPage}>
                  <ChevronRightIcon style={this.getIconRightStyle(webStyle)} />
                </Grid>
              </Grid>}
            </Grid> :
            <Grid container spacing={2} style={webStyle.mainContainer}>
              <CustomTypography component="title2">{configJSON.createCollectionText}</CustomTypography>
              <CustomTypography variant="outfitBody1" component="font_color">{configJSON.viewContentText}</CustomTypography>
              <Grid style={webStyle.collectionBtn}>
                <CustomButton variant="secondary" fullWidth="fullWidth" size={"large"} onClick={openCollectionModal}>{configJSON.collectionBtn}</CustomButton>
              </Grid>
            </Grid>
          }
        </Grid>
        <CustomSnackBar
          open={showAlert}
          onClose={this.handleAlertClose}
          title={userSuccessAlert}
          horizontal="center"
          variant={this.showAlertType()}
        />
        <ModalComponent open={this.state.showDeleteDialog} maxWidth={541} maxHeight={300}>
                <DeleteParent>
                  <CustomTypograpyParent>
                    <CustomTypography
                      variant={"primary"}
                      component={"body6"}
                      textTransform={"uppercase"}
                    >
                      {configJSON.deleteDialogTitle}
                    </CustomTypography>
                  </CustomTypograpyParent>
                  <Divider variant="whiteDark"></Divider>
                  <ParentDiv>
                    <CustomTypograpyParent1>
                      <CustomTypography variant={"secondary"} component={"body10"}>
                        {configJSON.deleteCollectionContent}
                      </CustomTypography>
                    </CustomTypograpyParent1>
                    <ActionDiv>
                      <ActionDivInner>
                        <CustomButton
                          onClick={this.handleCloseDeleteDialog}
                          variant="secondary"
                          fullWidth="fullWidth"
                          data-test-id="cancel_button"
                          size={"medium"}
                        >
                        {configJSON.cancelBtnLabel}
                        </CustomButton>
                      </ActionDivInner>
                      <ActionDivInner>
                        <CustomButton
                          variant="red"
                          fullWidth="fullWidth"
                          data-test-id="delete_confirmation"
                          size={"medium"}
                          onClick={this.deleteCollectionApi}
                        >
                        {configJSON.deleteBtnLabel}
                        </CustomButton>
                      </ActionDivInner>
                    </ActionDiv>
                  </ParentDiv>
                </DeleteParent>
              </ModalComponent>
      </Grid>

    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle: any = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    overflow: "hidden",
    backgroundColor: customTheme.palette.white.main,
    justifyContent: "center",
    alignItems: "center",
    height: "350px",
  },
  mainShowContainer: {
    display: "flex",
    overflow: "hidden",
    backgroundColor: customTheme.palette.white.main,
    alignItems: "center",
    width: "100%",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
    height: "100%",
    padding: "30px 0px"
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    backgroundColor: customTheme.palette.white.main,
    gap: '20px',
    overflow: "hidden",
    paddingRight: "50px",
    marginBottom: "20px"
  },
  collectionBtn: {
    width: "224px",
    height: "48px"
  },
  showCollectionData: {
    width: "406px",
    height: "296px",
  },
  showAllData: {
    display: "flex",
    width: "100%",
    justifyContent: "space-evenly"
  },
  showImage: {
    width: "100%",
    height: "100%"
  },
  sortStyle: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    gap: "5px"
  },
  sortDetails: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between"
  },
  contentDetail: {
    display: "flex",
    gap: "5px",
    alignItems: "center"
  },
  iconSize: {
    width: "18px",
    height: "18px"
  },
  iconSizes: {
    width: "16px",
    height: "16px",
    paddingRight: '10px'
  },
  selectedOption: {
    fontSize: "12px",
    fontFamily: "Outfit-Light",
    fontWeight: 400
  },
  collectionContent: {
    cursor: "pointer", diaply: "flex", height: "232px"
  },
  pageBtn: {
    backgroundColor: customTheme.palette.disabled.main,
    width: "24px",
    height: "24px",
    padding: "10px"
  },
  disabled: {
    color: customTheme.palette.secondary.main
  },
  able: {
    color: "black",
    cursor: "pointer"
  },
  imageSet: { display: "grid", gridGap: "5px", height: "100%" },
  pointer: { cursor: "pointer" }

};
const CustomTypograpyParent1 = styled("div")({
  textAlign:"center",
  padding:"32px 0px",
  "@media(max-width:600px)": {
    "& br": {
      display:"none",
    },
  },
});
const ParentDiv = styled("div")({
  padding:"0px 32px",
});
const DeleteParent =styled("div")({
  padding:"12px",
});
const CustomTypograpyParent =styled("div")({
  display:"flex",
  justifyContent:"center",
});
const ActionDiv =styled("div")({
  display: "flex",
  gap: "24px",
});
const ActionDivInner =styled("div")({
  width:"100%",
});

// Customizable Area End
