export const uploadImageIcon = require("../assets/uploadIcon.png")
export const deleteIcon = require("../assets/deleteIcon.png")
export const multiSelectIcon = require("../assets/multiSelectIcon.png")
export const saveIcon = require("../assets/saveIcon.png")
export const doubleTickIcon = require('../assets/doubleTick.png')
export const moreIcon = require('../assets/moreIcon.png')
export const editIcon = require('../assets/editBtn.png')
export const preview = require('../assets/preview.png')
export const warnIcon = require('../assets/warnIcon.png')
export const error = require('../assets/error.png')
export const process = require('../assets/process.png')
export const success = require('../assets/success.png')
export const failed = require('../assets/failed.png')
export const search = require('../assets/searchIcon.png')

