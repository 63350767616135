Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";

exports.searchApiEndPoint = "bx_block_landingpage2/landingpages/search"
exports.featuredApiEndPoing="bx_block_landingpage2/landingpages/photographers"
exports.becomeContributorSection = [{
  header:"Create",
  subHeading:"Craft Premium Visual Content for Our Customers to Download and Enjoy."
},
{
  header:"Submit",
  subHeading:"Effortlessly Upload Your Content with Our User-Friendly Platform and Unlock Success Strategies."
},
{
  header:"Get Paid",
  subHeading:"Earn with Every Download: Monetize Your Content Globally with QStockMedia."
},
{
  header:"Refer",
  subHeading:"Boost Your Earnings: Refer New Contributors and Customers for Additional Income Opportunities."
}
]
exports.assestType =[{label:"Images",value:"image"}]
exports.publishedType = [{label:"Published",value:"published"}]
// Customizable Area End