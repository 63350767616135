Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.advancedsearchApiContentType = 'application/json'
exports.getAdvancedSearchApiEndPoint = 'advanced_search/search'
exports.errorTitle = 'Error'
exports.getRecommendationApiEndPoint = 'bx_block_advanced_search/search/search_recommendation?title='
exports.getFiltersApiEndPoint = 'bx_block_advanced_search/search/filter_type'
exports.getSearchReasultsApiEndPoint = "bx_block_landingpage2/landingpages/filter_search"
// Customizable Area End
