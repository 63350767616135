//@ts-nocheck
import React, { Fragment } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CancelIcon from '@material-ui/icons/Cancel';
  // Customizable Area Start
import ImageContent from "./ImageContent.web";
import VideoContent from "./VideoContent.web";
// Customizable Area End

import ContentManagementController, {
  Props,
  configJSON,
} from "./ContentManagementController";
  // Customizable Area Start
// Customizable Area End


export default class ContentManagement extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <>
        <ImageContent/>
        <VideoContent/>
      </>
      // Customizable Area End

    );
  }
}
// Customizable Area Start
// Customizable Area End
