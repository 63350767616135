export const profileIcon = require("../assets/profileicon.png");
export const profileMain = require("../assets/profilemain.png");
export const subProfile = require("../assets/subprofile.png");

export const Profile1 = require("../assets/Profile1.png");
export const subProfile1 = require("../assets/subProfile1.png");
export const Profile2 = require("../assets/Profile2.png");
export const subProfile2 = require("../assets/subProfile2.png");
export const Profile3 = require("../assets/Profile3.png");
export const subProfile3 = require("../assets/subProfile3.png");
export const contributorIcon1 = require("../assets/contributorIcon1.png");
export const contributorIcon2 = require("../assets/contributorIcon2.png");
export const contributorIcon3 = require("../assets/contributorIcon3.png");
export const contributorIcon4 = require("../assets/contributorIcon4.png");
export const bgImageFooter = require("../assets/cbbgfooter.png");
export const easyToolImg = require("../assets/easy-tools.png");
export const communityImg = require("../assets/img-community.png");
export const globalFamilyImg = require("../assets/globalfamily.png");
export const cameraImg = require("../assets/camera.png");
export const bannerImg = require("../assets/bannerImg.png");
export const workshopImg = require("../assets/video_workshop.png");
export const shotListImg = require("../assets/shot-list.png");
export const contributorImg = require("../assets/contributor.png");
export const arrContributorIcon = [
  contributorIcon1,
  contributorIcon2,
  contributorIcon3,
  contributorIcon4
];
export const doubleTickIcon = require('../assets/doubleTick.png')
export const uploadIcon = require('../assets/upload.png')
export const mapImage = require("../assets/image_world.png")
export const uploadIconWhite = require('../assets/uploadIcon_white.png')
export const imageAI = require('../assets/image_ai.png')
export const  imageAI2= require('../assets/image_ai1.jpeg')
