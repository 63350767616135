import React from "react"

interface Props{
    color?:string,
}

const RequestIcon =(props:Props)=>(
    <>
    <svg width="24" height="24"  fill={props.color??"black"} xmlns="http://www.w3.org/2000/svg">
    <g id="group_icon">
    <path id="view_vector" d="M11.45 18L17.1 12.35L15.65 10.9L11.425 15.125L9.325 13.025L7.9 14.45L11.45 18ZM6.5 22C5.95 22 5.47917 21.8042 5.0875 21.4125C4.69583 21.0208 4.5 20.55 4.5 20V4C4.5 3.45 4.69583 2.97917 5.0875 2.5875C5.47917 2.19583 5.95 2 6.5 2H14.5L20.5 8V20C20.5 20.55 20.3042 21.0208 19.9125 21.4125C19.5208 21.8042 19.05 22 18.5 22H6.5ZM13.5 9V4H6.5V20H18.5V9H13.5Z" />
    </g>
    </svg>
    </>

)

export default RequestIcon