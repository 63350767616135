export const deleteIcon = require("../assets/delete.png");
export const checkedIcon = require("../assets/checked.png");
export const uncheckedIcon = require("../assets/unchecked.png");
export const searchIcon = require("../assets/searchIcon.png");
export const fileIcon = require("../assets/file.png");
export const eyeIcon = require('../assets/eyeIcon.png');
export const placeholderImage = require('../assets/place.jpg');
export const moreIcon =require('../assets/moreIcon.png');
export const editIcon = require('../assets/editIcon.png');
export const videoPlaceholder = require('../assets/videoPlaceholder.png')
export const selectIcon = require('../assets/selectIcon.png')
export const addContent = require('../assets/addContent.png')
export const viewIcon = require('../assets/view.png')
