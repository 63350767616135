import React from "react";
import {
  Modal
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import CustomButton from "../CustomButton/CustomButton.web";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '604px',
    overflowX: 'hidden',
    overflowY: 'scroll',
    minHeight: '500px',
    maxHeight: 'calc(100% - 200px)'
  },
  closeIcon: {
    position: 'relative',
    width: '100%',
    textAlign: 'right',
    paddingTop: '15px',
    paddingRight: '20px'
  },
  icon: {
    cursor: 'pointer'
  },
  contained: {
    background: '#0E0F17',
    borderRadius: '0px',
    color: '#fff',
    height: '46px',
    '&:hover': {
      background: '#BFC2C3',
      borderRadius: '0px',
      color: '#fff',
      height: '46px',
    }
  },
  inverted: {
    background: '#fff',
    borderRadius: '0px',
    color: '#0E0F17',
    height: '46px',
    '&:hover': {
      background: '#fff',
      borderRadius: '0px',
      color: '#0E0F17',
      height: '46px'
    },
  },
  outlined: {
    background: '#fff',
    borderRadius: '0px',
    border: '1px solid #0E0F17',
    color: '#0E0F17',
    height: '46px'
  },
  basicInverted: {
    color: '#ffffff'
  }
}));

const CustomModal = (props: any) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(props.openProp);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.backRes();
  };

  const modalAspects = {
    width: props.modalWidth,
    height: props.modalHeight,
    minHeight:props.modalMinHeight
  }

  return (
      <div>
        <CustomButton
          variant={props.variant}
          size={props.size ? props.size : 'large'}
          fullWidth='fullWidth'
          onClick={handleOpen}
        >
          {props.btnLabel}
        </CustomButton>
        <Modal
          open={open}
          onClose={handleClose}
          className={classes.modal}
        >
          <div className={classes.paper} style={modalAspects}>
            {
              props.closeBtn ?
                <div
                  className={classes.closeIcon}
                >
                  <CloseIcon 
                    onClick={handleClose}
                    className={classes.icon}
                  />
                </div>
              : null
            }
            {props.children}
          </div>
        </Modal>
      </div>
  );
}

export default CustomModal