import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface IContent {
  id: string;
  type: string | null;
  price: number | null;
  status: string;
  date: Date;
  file_link: string | null
  user_detail: {
    id: number;
    name: string;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  contentCount: {
    total_approved: number;
    percentage_approved: number
    total_submitted: number;
    percentage_submitted: number
    total_pending: number;
    percentage_pending: number;
    total_rejected: number;
    percentage_rejected: number;
  };
  selectedTab:number;
  selectedSortType: { value: string | number; label: string };
  contents: IContent[];
  searchQuery: string;
  showDeleteDialog:boolean;
  contenDeleteID:any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ReviewApprovalModeratorController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getContentApiCallId: any;
  getContentCountApiCallId: any;
  debounceTimer: any;
  deleteContentApiCallId:string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      token: "",
      contents: [],
      selectedTab:0,
      selectedSortType:configJSON.sortBy[0],
      searchQuery: '',
      contentCount: {
        total_approved: 0,
        percentage_approved: 0,
        total_pending:0,
        percentage_pending: 0,
        total_rejected: 0,
        percentage_rejected: 0,
        total_submitted: 0,
        percentage_submitted: 0,
      },
      showDeleteDialog:false,
      contenDeleteID:""
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message)

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = localStorage.getItem('authToken') || '';
      runEngine.debugLog("TOKEN", token);
      if (token) {
        this.setState({token});
      }
      this.getTotalCount(token)
      this.getListRequest({token})
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.getContentApiCallId:
        this.setState({ contents: responseJson})
        break;
      case this.getContentCountApiCallId:
        this.setState({ contentCount: responseJson})
        break;
        case this.deleteContentApiCallId:
          this.setDeletedContentCall()
          break;
      default:
        break;
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  getListRequest = ({token, selectedTab, sortBy, query}: {token?:string,  query?: string, selectedTab?: number, sortBy?:string | number}) => {
    const tab  = selectedTab || this.state.selectedTab
    let status = ''
    switch (tab) {
      case 0:
        status='accepted'
        break;
      case 1:
        status='pending'
          break;
      case 2:
        status='rejected'
        break;
      case 3:
        status='all'
        break;
      default:
        break;
    }

    const order = sortBy || this.state.selectedSortType.value
    const searchQuery = query || this.state.searchQuery

    let params = ''

    if(status !== "all") {
      params = `search_query=${status}`
    }
    if(order !== "sortBy") {
      params = `${params}${params !== '' ? '&' : ''}order=${order}`
    }
    if(searchQuery) {
      params = `${params}${params !== '' ? '&' : ''}name=${encodeURIComponent(searchQuery)}`
    }

    const header = {
      "Content-Type": configJSON.contentApiContentType,
      token: token || this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getContentApiCallId = requestMessage.messageId;
    let endPoint = configJSON.contentApiEndPoint + (params !== '' ? `?${params}`   : '')
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
   
    runEngine.sendMessage(requestMessage.id, requestMessage)

  }

  getTotalCount = (token: any) => {
    const header = {
      "Content-Type": configJSON.contentApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getContentCountApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.totalContentApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleChangeTabs = (event: React.ChangeEvent<{}>, newValue: number) => {
      this.setState({selectedTab:newValue}) 
      this.getListRequest({selectedTab: newValue})
  };

  handleChangeSortType = (option: {
    value: string | number;
    label: string;
  }) => {
    this.setState({ selectedSortType: option });
    this.getListRequest({sortBy: option.value})
  };

  handleInputChange = (value: string) => {
    this.setState({ searchQuery: value });
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    this.debounceTimer = setTimeout(() => {
      this.getListRequest({ query: value });
    }, 1000);
  };

  handleRedirectToContentDetails = (id: string)=>{
    this.props.navigation.navigate("ReviewSetPriceModerator", { id })
  }
  deletecontent = (deleteID:any) => {
    this.setState({showDeleteDialog: true,contenDeleteID:deleteID})
  }
  handleCloseDeleteDialog  = () => {
    this.setState({showDeleteDialog: false})
  }
  handleDeleteContent = () => {
    this.setState({showDeleteDialog: false})

    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.deleteContentApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteContentEndPoint.replace(
        ":id",
        this.state.contenDeleteID
      )
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  setDeletedContentCall = () => {
    this.getListRequest({});
    this.getTotalCount(this.state.token)
  }
  // Customizable Area End
}
