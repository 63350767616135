export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const edit = require("../assets/edit.png");
export const profileIcon = require("../assets/profileicon.png");
export const profile = require("../assets/profile.png");
export const upload = require("../assets/upload.png");
export const sad = require("../assets/sad.png");
export const errorIcon = require("../assets/erroricon.png");
export const SearchIcon = require("../assets/search_icon.svg");
export const downArrow = require("../assets/down_arrow.svg");
export const DeleteIcon=require("../assets/delete.png");
export const EditIcon=require("../assets/editIcon.png");
export const CheckIcon=require("../assets/view_shape.png");







