import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  portFolioStatusTab:number
  selectedAssetType: { value: string | number; label: string };
  selectedPublishedType: { value: string | number; label: string };
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageAfterLoginContributorController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage)
    ];

    this.state = {
      portFolioStatusTab:0,
      selectedAssetType:configJSON.assestType[0],
      selectedPublishedType:configJSON.publishedType[0]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    console.log(from, message);
    // Customizable Area End
  }

  // Customizable Area Start
    handleChangePortfolioTab = (event: React.ChangeEvent<{}>, newValue: number) => {
      this.setState({portFolioStatusTab:newValue}) 
  };


  handleChangeAssetType = (option: {
    value: string | number;
    label: string;
  }) => {
    this.setState({ selectedAssetType: option });
  };


  handleChangePublishedType = (option: {
    value: string | number;
    label: string;
  }) => {
    this.setState({ selectedPublishedType: option });
  };

  uploadContentBtn = ()=>{
        const goToUploadCatalogue: Message = new Message(getName(MessageEnum.NavigationMessage));
    goToUploadCatalogue.addData(getName(MessageEnum.NavigationTargetMessage), "Catalogue");
    goToUploadCatalogue.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(goToUploadCatalogue);
      }
      collectionSectionBtn = () => {
        const goToUploadCatalogue: Message = new Message(getName(MessageEnum.NavigationMessage));
    goToUploadCatalogue.addData(getName(MessageEnum.NavigationTargetMessage), "PhotoLibrary");
    goToUploadCatalogue.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(goToUploadCatalogue);
      }

  // Customizable Area End
}
