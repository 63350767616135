import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  email: string;
  emailInputError: boolean;
  emailHelperText: string;
  error: boolean;
  errorText: string;
  errorType: string;
  password: string;
  confirmPwd: string;
  passwordError: boolean;
  passwordHelperText: string;
  confirmPwdError: boolean;
  confirmPwdHelperText: string;
  disabled: boolean;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ResetPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  emailRegEx: RegExp;
  passwordRegEx: RegExp;
  resetPwdApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
        email: '',
        emailInputError: false,
        emailHelperText: '',
        error: false,
        errorText: '',
        errorType: '',
        password: '',
        confirmPwd: '',
        passwordError: false,
        passwordHelperText: '',
        confirmPwdError: false,
        confirmPwdHelperText: '',
        disabled: true,
        loading: false
    };
    this.emailRegEx = new RegExp(`${configJSON.emailRegEx}`);
    this.passwordRegEx = new RegExp(`${configJSON.pwdRegEx}`);
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiReqCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiReqCallId === this.resetPwdApiCallId) {
        if (resJson && !resJson.errors && !resJson.error) {
          this.setState({
            error: false,
            errorText: "",
            errorType: "",
            loading: false
          }, () => {
            this.redirectToLandingPage()
          });
        } else {
          this.setState({
            error: true,
            errorText: resJson.errors[0].token ? resJson.errors[0].token : 'Something Went Wrong!',
            errorType: "error",
            loading: false
          });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleChange = (target: any) => {
    this.setState({
      ...this.state,
      [target?.name]: target?.value
    }, () => {
      this.validate()
    })
  }

  validate = () => {
    if (
      !this.state.email ||
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailRegEx.test(this.state.email)
    ) {
      this.setState({
        error: true,
        errorText: 'Please enter a valid email!',
        errorType: 'error',
        disabled: true
      })
      return false
    } else if(!this.passwordRegEx.test(this.state.password)){
      this.setState({
        error: true,
        errorText: 'Please enter a valid password!',
        errorType: 'error',
        disabled: true
      })
      return false
    } else if(this.state.password !== this.state.confirmPwd){
      this.setState({
        error: true,
        errorText: 'New password and confirm password does not match!',
        errorType: 'error',
        disabled: true
      })
    } else {
      this.setState({
        error: false,
        errorText: '',
        errorType: '',
        disabled: false
      })
    }
  }

  resetPwd = () => {
    this.setState({
      loading: true
    })
    const searchParams = new URLSearchParams(window.location.href.split('?')[1]);
    const token = searchParams.get('token');
    const header = {
      "Content-Type": configJSON.contentTypeApplicatioJson,
      token
    };

    const body = {
      data: {
        email: this.state.email,
        new_password: this.state.password,
        confirm_password: this.state.confirmPwd,
        role_type: 0
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resetPwdApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPasswordApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  redirectToLandingPage = () => {
    this.props.navigation.navigate('LandingPage');
  }
  // Customizable Area End
}
