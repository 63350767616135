Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.endPointApiGetUserManagement = "account_block/accounts/user_list";
exports.contentTypeApiGetUserManagement = "application/json";
exports.methodTypeApiGetUserManagement = "GET";

exports.endPointApiGetDeleteUserManagement = "account_block/accounts/delete_user";
exports.contentTypeApiGetDeleteUserManagement = "application/json";
exports.methodTypeApiGetDeleteUserManagement = "GET";

exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.regexForMinimumLen = /^.{8,}$/;
exports.regexForUpperChar = /[A-Z]/;
exports.regexForLowerChar = /[a-z]/;
exports.regexForNumber = /\d/;
exports.publicVisibility = "set_public";
exports.PrivateVisibility = "set_private";
exports.FollowersVisibility = "follower_only";
exports.inAppNotificationAlert = "in_app_notification";
exports.emailNotificationAlert = "email";
exports.userProfileEndPoit = "/bx_block_profile/profiles/my_profile"
exports.contentTypeAppliactionJson = "application/json"
exports.getMethod = "GET"
exports.patchMethod = "PATCH"
exports.getVisibilityAndSecurityAlertEndPoint = "/bx_block_profile/profiles/get_visibility_and_security_alert"
exports.updateVisibilityAndSecurityAlertEndPoint = "/bx_block_profile/profiles/update_visibility_and_security_alert"
exports.updatePasswordEndPoint = "/bx_block_profile/profiles/update_password"
exports.visibility={set_public: 0,set_private: 1,follower_only: 2}
exports.security_alert = {in_app_notification: 0,email: 1}
exports.numberRegEx = /^\+?\d+$/;
exports.userProfileEndPoit="/bx_block_profile/profiles/my_profile"
exports.contentTypeAppliactionJson="application/json"
exports.getMethod="GET";
exports.patchMethod="PATCH";
exports.getNotificationPrefrencesEndPoint = "bx_block_settings/show_settings";
exports.getCommunicationPrefrencesEndPoint = "/bx_block_notifications/communication_preferences";
exports.updateNotificationPrefrencesEndPoint = "bx_block_settings/update_notification";
exports.updateCommunicationPrefrencesEndPoint = "/bx_block_notifications/communication_preferences";
exports.profileUpdateEndPoint="/bx_block_profile/profiles/update_profile";
exports.profileUpdateMethodtype="PATCH";
exports.otpAllowDigit=/\D/g;
exports.nameValidation=/^$|^[A-Za-z\s]+$/;
exports.emailRegEx = "[^@]+@[^\s]+\.com$";
exports.confirmPasswordMsg = "Confirm Password Not Match";
exports.sortType=[{label:"SORT BY",value:""},{label:"ASC",value:"asc"},{label:"DESC",value:"desc"}]
// Customizable Area End

