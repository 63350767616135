export const bag = require('../../../assets/bag.png')
export const profile = require('../../../assets/profile.png')
export const notification = require('../../../assets/notification.png')
export const logo = require('../../../assets/logo.png')
export const checkboxone = require('../../../assets/checkbox.png')
export const labelnameone = require('../../../assets/labelname.png')
export const frameone1 = require('../../../assets/frameone.png')
export const frameone2 = require('../../../assets/frameone.png')
export const frameone3 = require('../../../assets/frameone.png')
export const frameone4 = require('../../../assets/frameone.png')
export const frameone5 = require('../../../assets/frameone.png')
export const whiteBag = require('../../../assets/white-bag.png')
export const logouticon = require('../../../assets/logouticon.png')

