import React from "react";
// Customizable Area Start
import ReviewSetPriceModeratorController, {
  Props,configJSON
} from "./ReviewSetPriceModeratorController";
import { 
    Box, 
    styled,
    Breadcrumbs,
    Link,
    SvgIcon,
    TextField,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox,
    Chip,Grid,
    Paper, } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomTypography from "../../../components/src/DesignSystem/CustomTypography/CustomTypography.web";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import Input from "../../../components/src/DesignSystem/Input/Input.web";
import PriceCard from "./PriceCard.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import SideBar from "../../../components/src/DesignSystem/SideBar/SideBar.web";
import {sideBarListItem, sideBarBottomNav } from "../../../blocks/navigationmenu/src/NavigationMenuController";
import ModalComponent from "../../../components/src/DesignSystem/Modalcomponent/ModalComponent.web";
import CustomButton from "../../../components/src/DesignSystem/CustomButton/CustomButton.web";
import Divider from "../../../components/src/DesignSystem/Divider/Divider.web";
export const images = require('./assets')
// Customizable Area End

class ReviewSetPriceModerator extends ReviewSetPriceModeratorController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  
  render() {
    const {location, suggestionLocation} = this.state;
    return (
      // Customizable Area Start
      <Grid>
      <NavigationMenu navigation={undefined} id={""} ModeratorLogin={true} contributorLogin={true} contributorContent={false} data-test-id="showCollection"/>
          <SideBar id="" navigation={this.props.navigation} sideBarBottomNav={sideBarBottomNav} sideBarListItem={sideBarListItem}>
          <CustomContainer>
            <Box style={webStyles.topContainer}>
              <Breadcrumbs>
                <Link color="inherit" href="/Moderator/review/">
                  Review
                </Link>
                <CustomTypography color="textPrimary">Set Price</CustomTypography>
              </Breadcrumbs>
              <Box style={webStyles.groupIconContainer}>
                <Box style={webStyles.iconContainer} data-test-id="deletecontent" onClick={this.deletecontent}>
                    <SvgIcon>
                        <DeleteOutlineIcon />
                    </SvgIcon>
                    <CustomTypography variant={"secondary"} component="label" >
                        Delete
                    </CustomTypography>
                </Box>
                <Box style={webStyles.iconContainer}>
                    <SvgIcon>
                        <SaveOutlinedIcon />
                    </SvgIcon>
                    <CustomTypography variant={"secondary"} component="label" >
                        Save
                    </CustomTypography>
                </Box>
              </Box>
            </Box>
            <Box style={webStyles.contentContainer}>
              <Box style={webStyles.imageContainer}>
                <ContentImg src={this.state.contentDetails?.file_link} />
              </Box>
              <Box style={webStyles.detailContainer}>
                <CustomTypography style={{color: '#73767A'}} variant="outfitBody2" textTransform='capitalize' gutterBottom id="modal-modal-title">
                  {`Date ${this.state.contentDetails?.date 
                    ? new Date(this.state.contentDetails.date).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric'
                      }).replace(/,/g, '')
                    : ''
                    }`}
                </CustomTypography>
                <Box style={webStyles.fieldStyle2}>
                  <CustomTypography variant="outfitBody2" textTransform='capitalize' gutterBottom id="modal-modal-title">
                    {configJSON.contentTitle}
                  </CustomTypography>
                  <Box style={webStyles.inputContainer}>
                    <Input
                      data-test-id="txtTitle"
                      placeholder={configJSON.titlePlaceholder}
                      fullWidth={true}
                      value={this.state.titleText}
                      disableUnderline={true}
                      maxLength={100}
                      style={webStyles.inputStyle}
                      onChange={this.handleTitleChange}
                    />
                    <CustomTypography variant="outfitBody3">{`${this.state.titleText.length}/100`}</CustomTypography>
                  </Box>
                </Box>
                
                <Box style={webStyles.fieldStyle}>
                  <CustomTypography variant="outfitBody2" textTransform='capitalize' gutterBottom id="modal-modal-title">
                    {configJSON.contentDescription}
                  </CustomTypography>
                 <Box style={webStyles.inputContainer}>
                  <TextField
                      data-test-id="txtDescription"
                      id="standard-multiline-static"
                      value={this.state.descriptionText}
                      multiline
                      placeholder={configJSON.descriptionPlaceholder}
                      fullWidth
                      minRows={4}
                      variant="outlined"
                      onChange={this.handleDescriptionChange}
                    />
                    <CustomTypography variant="outfitBody3">{`${this.state.descriptionText.length}/500`}</CustomTypography>

                  </Box>
                </Box>
                <Box style={webStyles.fieldStyle2}>
                  <CustomTypography variant="outfitBody2" textTransform='capitalize' gutterBottom id="modal-modal-title">
                    {configJSON.contentSpecification}
                  </CustomTypography>
                  <Box style={webStyles.inputContainer}>
                    <Input  
                      data-test-id={"txtSpecification"}
                      placeholder={configJSON.specificationPlaceholder}
                      value={this.state.specificationText}
                      fullWidth={true}
                      style={webStyles.inputStyle}
                      disableUnderline={true}
                      onChange={this.handleSpecificationChange}
                      maxLength={100}
                    />
                    <CustomTypography variant="outfitBody3">{`${this.state.specificationText.length}/100`}</CustomTypography>
                  </Box>

                </Box>
                <Box style={webStyles.fieldStyle}>
                  <CustomTypography variant="outfitBody2" textTransform='capitalize' gutterBottom id="modal-modal-title">
                    {configJSON.contentCategory}
                  </CustomTypography>
                  <Select
                    data-test-id={"txtCategory"}
                    placeholder={configJSON.categoryPlaceholder}
                    fullWidth={true}
                    style={webStyles.inputStyle}
                    disableUnderline={true}
                    IconComponent={ExpandMoreIcon}
                  >
                    {this.state.categories?.map((category) => 
                      <MenuItem key={`category-${category.id}`} value={category.id}>{category.title}</MenuItem>
                    )}
                    
                  </Select>
                </Box>
                <Box style={webStyles.fieldStyle}>
                  <FormControlLabel 
                    control={<Checkbox />} 
                    label={<Box>
                      <CustomTypography variant="outfitBody4" textTransform='capitalize' gutterBottom id="modal-modal-title">
                        {configJSON.nsfwTitle}
                      </CustomTypography>
                      <CustomTypography variant="outfitBody5" textTransform='capitalize' gutterBottom id="modal-modal-title">
                        {configJSON.nsfwDescription}
                      </CustomTypography>
                    </Box>
                    } 
                  />
                </Box>
                <Box style={webStyles.fieldStyle2}>
                  <CustomTypography variant="outfitBody2" textTransform='capitalize' gutterBottom id="modal-modal-title">
                    {configJSON.contentLocation}
                  </CustomTypography>
                <Input
                            placeholder="Enter location"
                            type='text'
                            required
                            value={location}
                            onChange={this.handleInputLocationChange}
                            name="location"
                            data-test-id="handleFormChangeLocation"
                            
                            startAdornment=
                            {<img src={images.searchIcon} alt="Search" />} />
                            {suggestionLocation.length > 0 && (
          <Grid style={webStyles.locationStyle}>
            {suggestionLocation.map((suggestion: any, index: number) => (
              <div
                key={index}
                data-test-id="handleSuggestionClick"
                style={webStyles.styledLocation}
                onClick={() => this.handleSuggestionClick(suggestion)}
              >
               <CustomTypography component="outfitBody5">{suggestion}</CustomTypography> 
              </div>
            ))}
         </Grid>
        )}
        
                </Box>
                <Box style={webStyles.fieldStyle}>
                  <CustomTypography variant="outfitBody2" textTransform='capitalize' gutterBottom id="modal-modal-title">
                    {configJSON.contentKeyword}
                  </CustomTypography>
                  <Input
                    data-test-id={"txtKeyword"}
                    placeholder={configJSON.keywordPlaceholder}
                    fullWidth={true}
                    style={webStyles.inputStyle}
                    disableUnderline={true}
                  />
                </Box>
                <Box style={webStyles.keywordStyle}>
                  <CustomTypography variant="outfitBody2" textTransform='capitalize' gutterBottom id="modal-modal-title">
                    {configJSON.contentKeyword}
                  </CustomTypography>
                  
                  <Paper component="ul" style={webStyles.chipset}>
                    {configJSON.chipData.map((data:any) => {
                      return ( 
                        <li key={data.key}>
                          <Chip variant="outlined" label={data.label} color="primary" deleteIcon={<ClearIcon />} style={webStyles.chip} />
                        </li>
                       );
                     })}
                  </Paper>
                </Box>
                <Box style={webStyles.fieldStyle}>
                  <CustomTypography variant="subtitle2" gutterBottom id="modal-modal-title">
                    {configJSON.setThePriceLabel}
                  </CustomTypography>
                  <CustomTypography variant="outfitBody2" textTransform='capitalize' gutterBottom id="modal-modal-title">
                    {configJSON.setThePriceDescription}
                  </CustomTypography>
                  <Select
                    data-test-id={"txtCategory"}
                    placeholder={configJSON.categoryPlaceholder}
                    fullWidth={true}
                    style={webStyles.inputStyle}
                    disableUnderline={true}
                  >
                    <MenuItem value={10}>Category A</MenuItem>
                    <MenuItem value={20}>Category B</MenuItem>
                    <MenuItem value={30}>Category C</MenuItem>
                  </Select>
                  <Select
                    data-test-id={"txtCategory"}
                    placeholder={configJSON.categoryPlaceholder}
                    fullWidth={true}
                    style={webStyles.inputStyle}
                    disableUnderline={true}
                  >
                    <MenuItem value={10}>Category A</MenuItem>
                    <MenuItem value={20}>Category B</MenuItem>
                    <MenuItem value={30}>Category C</MenuItem>
                  </Select>
                </Box>
                <Box style={webStyles.fieldStyle}>
                  <CustomTypography variant="outfitBody2" textTransform='capitalize' gutterBottom id="modal-modal-title">
                    {configJSON.selectPriceLabel}
                  </CustomTypography>
                  <Box style={webStyles.priceContainer}>
                    <PriceCard title="SMALL" value='$279'isActive />
                    <PriceCard title="MEDIUM" value='$379' />
                    <PriceCard title="LARGE" value='$479' />
                    <PriceCard title="NON PROFIT" value='$179' />
                  </Box>
                </Box>
                <Box style={webStyles.fieldStyle}>
                  <CustomButton fullWidth="fullWidth" variant="primary" size="large">{configJSON.submitBtn}</CustomButton>
                </Box>

                <Box style={webStyles.actionBtns}>
                  <Grid style={webStyles.btns}>
                  <CustomButton  variant="primary" fullWidth="fullWidth" size="large">{configJSON.approveBtn}</CustomButton>
                  </Grid>
                  <Grid style={webStyles.btns}>
                  <CustomButton  variant="secondary" fullWidth="fullWidth" size="large">{configJSON.rejectBtn}</CustomButton>
                  </Grid>
                </Box>
              </Box>
            </Box>
            <ModalComponent open={this.state.showDeleteDialog} maxWidth={541} maxHeight={300}>
                <DeleteParent>
                  <CustomTypograpyParent>
                    <CustomTypography
                      variant={"primary"}
                      component={"body6"}
                      textTransform={"uppercase"}
                    >
                      {configJSON.deleteDialogTitle}
                    </CustomTypography>
                  </CustomTypograpyParent>
                  <Divider variant="whiteDark"></Divider>
                  <ParentDiv>
                    <CustomTypograpyParent1>
                      <CustomTypography variant={"secondary"} component={"body10"}>
                        {configJSON.deleteContent}
                      </CustomTypography>
                    </CustomTypograpyParent1>
                    <ActionDiv>
                      <ActionDivInner>
                        <CustomButton
                          onClick={this.handleCloseDeleteDialog}
                          variant="secondary"
                          fullWidth="fullWidth"
                          data-test-id="cancel_button"
                          size={"medium"}
                        >
                        {configJSON.cancelBtnLabel}
                        </CustomButton>
                      </ActionDivInner>
                      <ActionDivInner>
                        <CustomButton
                          variant="red"
                          fullWidth="fullWidth"
                          data-test-id="delete_confirmation"
                          size={"medium"}
                          onClick={this.handleDeleteContent}
                        >
                        {configJSON.deleteBtnLabel}
                        </CustomButton>
                      </ActionDivInner>
                    </ActionDiv>
                  </ParentDiv>
                </DeleteParent>
              </ModalComponent>
          </CustomContainer>
     </SideBar></Grid>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
 
const webStyles: any = {
  topContainer: {
    height: '3.5rem',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '2.5rem',
    paddingRight: '2.5rem',
    justifyContent: 'space-between',
    border: '1px solid #BFC2C3'
  },
  groupIconContainer:{
    display: 'flex',
    alignItems: 'center',
    gap: '1.25rem',

  },
  iconContainer: {
    textAlign:"center",
    cursor:"pointer",
    "& svg":{
        marginBottom:"2px"
    }
  },
  contentContainer: {
    display: 'flex',
    border: '1px solid #BFC2C3',
    bordderTop: 0
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    gap: '0.25rem'
  },
  imageContainer: {
    flex: '1 1 0%',
    borderRight: '1px solid #BFC2C3',
    padding: '2rem 1.5rem'
  },
  detailContainer: {
    flex: '1 1 0%',
    padding: '2rem 1.5rem'
  },
  priceContainer: {
    display: 'flex',
    gap: '1rem'
  },
  fieldStyle: {
    marginBottom: '1rem',
    gap: '0.75rem',
    display: 'flex',
    flexDirection: 'column'
  },
  fieldStyle2: {
    marginBottom: '1rem',
    display: 'flex',
    flexDirection: 'column'
  },
  inputStyle: {
    border: '1px solid #BFC2C3',
    minHeight: '3rem',
    padding: '0.875rem 1rem',
    marginTop: '0px',
    marginBottom: '0px'
  },
  chipset: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    gap: '0.5rem',
    margin: 0,
    padding: 0,
    boxShadow: 0
  },
  chip: {
    borderRadius: 0,
  },
  keywordStyle: {
    color: '#3A82FF',
    marginBottom: '2rem'
  },
  locationStyle : {
    zIndex:1,
    border:"1px solid #BFC2C3",
    maxHeight:"147px",
    padding:"10px",
    overflowY:"auto",
    height:"auto"
},
styledLocation:{
    padding:"5px 0px"
},
actionBtns:{
  display:"flex",
  gap:"20px",
  width:"100%"
},
btns:{
  width:"100%"
}
};

const CustomContainer = styled(Box)({
  width:"100%"
})
const DeleteParent = styled("div")({
  padding: "12px",
});

const TopMetrics = styled(Box)({
  gap: '1.5rem',
  height:"12.5rem",
  marginBottom:"2rem",
  display:"flex",
  width: '100%'
})

const CustomTypograpyParent = styled("div")({
  display: "flex",
  justifyContent: "center",
});

const ActionDiv = styled("div")({
  display: "flex",
  gap: "24px",
});

const ActionDivInner = styled("div")({
  width: "100%",
});

const ContentImg = styled("img")({
  width: "100%",
  height: "458px",
});

const CustomTypograpyParent1 = styled("div")({
  textAlign: "center",
  padding: "32px 0px",
  "@media(max-width:600px)": {
    "& br": {
      display: "none",
    },
  },
});
const ParentDiv = styled("div")({
  padding: "0px 32px",
});
 // Customizable Area End



export default ReviewSetPriceModerator;
