export const userProfile = require("../assets/user-profile.png");
export const logo = require('../assets/logo.png')
export const profile = require('../assets/profile.png')
export const language = require('../assets/language.png')
export const reviewIcon=require('../assets/review_icon.svg')
export const ticketIcon=require('../assets/ticket.svg')
export const settingIcon=require('../assets/setting.svg')
export const activeUserIcon=require('../assets/active_user_icon.svg')
export const watermarkIcon=require('../assets/watermark.svg')
export const orderIcon=require('../assets/order.svg')
export const accountIcon=require('../assets/account_icon.svg')
export const homeIcon = require('../assets/home.png')
export const homeActive = require('../assets/homeActive.png')
export const insight = require('../assets/insight.png')
export const insightActive = require('../assets/insightActive.png')
export const portfolioActive = require('../assets/portfolioActive.png')
export const earningIcon= require('../assets/earning.png')
export const earningActive= require('../assets/earningActive.png')
export const helpIcon = require('../assets/help.png')
export const requestActive = require('../assets/requestActive.png')
export const requestIcon = require('../assets/request.png')
export const portfolioIcon = require('../assets/portfolio.png')
export const activeWaterMark=require('../assets/activeWatermark.svg')
export const activeTicket=require('../assets/activeTicket.svg')
export const activeOrder=require('../assets/activeOrder.svg')
export const activeReview=require('../assets/activeReview.svg')
export const user=require('../assets/user.svg')






