import React from "react";
// Customizable Area Start
import { Box } from "@material-ui/core";
import CustomTypography from "../../../components/src/DesignSystem/CustomTypography/CustomTypography.web";
import CustomCard from "../../../components/src/DesignSystem/CustomCard/CustomCard.web";
import { imageAI} from "./assets"
import CustomButton from "../../../components/src/DesignSystem/CustomButton/CustomButton.web";

interface Props {
  
}
// Customizable Area End

class ContributorSurveyCard extends React.Component<Props>{
    render(){
         // Customizable Area Start
        return(
            <CustomCard variant="variant2" >
                <Box style={webStyles.container}>
                <Box style={webStyles.header}>
                    <CustomTypography variant="primary" component="title2" textTransform ="uppercase"   >Contributor Survey on Generative AI</CustomTypography>
                </Box>
                <Box style={webStyles.subTitle}>
                    <CustomTypography variant="secondary" component="outfitBody2" >
                        While Shutterstock does not currently accept content generated by AI, we are curious about how our contributors are currently using generative services. We’d appreciate your feedback.
                    </CustomTypography>
                </Box>
                <Box style={webStyles.footerSection}>
                    <Box style={webStyles.btnContainer} >
                        <CustomButton variant="primary" size="large" fullWidth="fullWidth">GIVE FEEDBACK</CustomButton>
                    </Box>
                </Box>
                </Box>
            </CustomCard>

             // Customizable Area End
        )
    }
}

// Customizable Area Start
 
const webStyles: any = {
    container:{
        display:"flex",
        flexDirection:"column",
        height:"100%"
    },
    header:{
        textAlign:"center",
        marginTop:"2.43rem",
        boxSizing: "border-box"
    },
    subTitle:{
        marginTop:"1rem",
        marginRight:"2.75rem",
        marginLeft:"2.75rem",
        textAlign:"center",
        boxSizing: "border-box"
    },
    footerSection:{
        backgroundImage:`URL(${imageAI})`,
        backgroundSize:"cover",
        backgroundPosition:"bottom",
        objectFit:"cover",
        marginTop:"1rem",
        position:"relative",
        flex:1,
        backgroundRepeat:"no-repeat",
        display:"flex",
        flexDirection:"column",
        minHeight:"34.25rem"
    },
    btnContainer:{
        margin:"auto",
        marginBottom:"2rem"
    }
  };

   // Customizable Area End

   export default ContributorSurveyCard