import React from 'react';
import Divider from '../Divider/Divider.web';
import CustomTypography from '../CustomTypography/CustomTypography.web';

const DividerWithOr = (props: any) => {
    return (
        <div
            style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <Divider 
                variant={props.variant}
            />
            <div
                style={{
                    paddingLeft: '10px',
                    paddingRight: '10px'
                }}
            >
                <CustomTypography 
                    variant={props.textVariant}
                    weight='light'
                    textTransform="uppercase"
                    component={'para'}
                >
                    or
                </CustomTypography>
            </div>
            <Divider 
                variant={props.variant}
            />
        </div>
    )
}

export default DividerWithOr;