
Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Product Title";
exports.labelBodyText = "ProductDescription Body";
exports.btnExampleTitle = "CLICK ME";

exports.productAPiEndPoint = "bx_block_catalogue/catalogues/";
exports.licenseTypeAPiEndPoint = "bx_block_catalogue/catalogues/get_license_type";
exports.getSizeAPiEndPoint = "bx_block_catalogue/catalogues/get_size";
exports.getFormatAPiEndPoint = "bx_block_catalogue/catalogues/get_format";
exports.getCommentAPiEndPoint = "bx_block_comments/comments/";
exports.similarImagesAPiEndPoint = "bx_block_catalogue/catalogues/similar_images/";
exports.userCatalogueAPiEndPoint = "bx_block_catalogue/catalogues/catalogue_user/";
exports.postCommentAPiEndPoint = "bx_block_comments/comments";
exports.repliesAPiEndPoint = "bx_block_comments/comments/create_replies";
exports.addToCartAPiEndPoint = "bx_block_shopping_cart/orders/add_to_cart";
exports.sentRequestAPiEndPoint = "bx_block_shopping_cart/orders/send_email";
exports.postFollowAPiEndPoint = "bx_block_content_management/follows";
exports.postLikeAPiEndPoint = "bx_block_favourites/favourites";

exports.postBookmarkAPiEndPoint = "bx_block_content_management/bookmarks";

exports.GetMethod = "GET";
exports.PostMethod = "POST";
exports.productApiContentType = "application/json";
// Customizable Area End
