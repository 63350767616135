import React from "react"

interface Props{
    color?:string,
}

const HomeIcon =(props:Props)=>(
    <>
    <svg width="24" height="24" viewBox="0 0 24 24" fill ={props.color??"black"} xmlns="http://www.w3.org/2000/svg">
        <path fill="inherit" id="view_vector" d="M18.5386 21.0938H5.89685C5.71037 21.0938 5.53153 21.0197 5.39967 20.8878C5.2678 20.7559 5.19373 20.5771 5.19373 20.3906V9.23438C5.19373 9.04789 5.2678 8.86905 5.39967 8.73719C5.53153 8.60533 5.71037 8.53125 5.89685 8.53125C6.08333 8.53125 6.26217 8.60533 6.39403 8.73719C6.5259 8.86905 6.59998 9.04789 6.59998 9.23438V19.6875H17.8354V9.23438C17.8354 9.04789 17.9095 8.86905 18.0414 8.73719C18.1732 8.60533 18.3521 8.53125 18.5386 8.53125C18.725 8.53125 18.9039 8.60533 19.0358 8.73719C19.1676 8.86905 19.2417 9.04789 19.2417 9.23438V20.3906C19.2417 20.5771 19.1676 20.7559 19.0358 20.8878C18.9039 21.0197 18.725 21.0938 18.5386 21.0938Z" />
        <path fill="inherit" id="view_vector_2" d="M20.8645 12.2648C20.7722 12.2649 20.6807 12.2467 20.5954 12.2114C20.5101 12.1761 20.4325 12.1243 20.3672 12.059L12.2175 3.90934L4.06781 12.059C3.9352 12.1871 3.75759 12.258 3.57324 12.2564C3.38888 12.2548 3.21253 12.1808 3.08216 12.0505C2.9518 11.9201 2.87785 11.7437 2.87625 11.5594C2.87465 11.375 2.94552 11.1974 3.0736 11.0648L11.7188 2.41778C11.8506 2.28596 12.0294 2.21191 12.2159 2.21191C12.4023 2.21191 12.5811 2.28596 12.713 2.41778L21.3619 11.0648C21.4601 11.1632 21.527 11.2884 21.5541 11.4248C21.5811 11.5612 21.5672 11.7025 21.5139 11.8309C21.4607 11.9593 21.3706 12.0691 21.255 12.1463C21.1394 12.2235 21.0035 12.2648 20.8645 12.2648Z" />
        <path fill="inherit" id="view_vector_3" d="M14.257 21.0937H10.1789C9.99241 21.0937 9.81357 21.0196 9.68171 20.8878C9.54985 20.7559 9.47577 20.5771 9.47577 20.3906V14.3193C9.47577 14.1329 9.54985 13.954 9.68171 13.8222C9.81357 13.6903 9.99241 13.6162 10.1789 13.6162H14.257C14.4435 13.6162 14.6223 13.6903 14.7542 13.8222C14.8861 13.954 14.9601 14.1329 14.9601 14.3193V20.3906C14.9601 20.5771 14.8861 20.7559 14.7542 20.8878C14.6223 21.0196 14.4435 21.0937 14.257 21.0937ZM10.882 19.6875H13.5539V15.0225H10.882V19.6875Z" />
    </svg>
    </>

)

export default HomeIcon