//@ts-nocheck

import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Divider,
  FormControl,
  Select,
  MenuItem,
  ImageList,
  ImageListItem,
  Checkbox,
  ListItemText,
  Radio,
  FormControlLabel
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import Header from "../../../../packages/components/src/DesignSystem/Header/Header.web";
import Footer from "../../../../packages/components/src/DesignSystem/Footer/Footer.web";
import CustomButton from "../../../../packages/components/src/DesignSystem/CustomButton/CustomButton.web";
import KeyboardVoiceIcon from "@material-ui/icons/KeyboardVoice";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import BookmarkOutlinedIcon from '@material-ui/icons/BookmarkOutlined';
import GetAppIcon from '@material-ui/icons/GetApp';
import CropFreeSharpIcon from '@material-ui/icons/CropFreeSharp';
import ShareIcon from '@material-ui/icons/Share';
import Avatar from '@material-ui/core/Avatar';
import { Watermark, basket } from "./assets";
import { FlatList } from "react-native";
import ModalComponent from "../../../../packages/components/src/DesignSystem/Modalcomponent/ModalComponent.web";
import InputComponent from "../../../../packages/components/src/DesignSystem/Input/Input.web"
import CustomTypography from "../../../../packages/components/src/DesignSystem/CustomTypography/CustomTypography.web";
import customTheme from "../../../../packages/components/src/DesignSystem/Theme/Theme.web";


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const themeDropdown = createTheme({
  overrides: {
    MuiSelect: {
      select: {
        '&:focus': {
          borderColor: 'blue', // Specify the desired background color
        },
      },
    },
  },
});

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 250,
      position:'absolute'
    },
  },
};
// Customizable Area End

import ProductDescriptionController, {
  Props,
  configJSON,
} from "./ProductDescriptionController";
import { dummyProduct } from "./assets";

export default class ProductDescription extends ProductDescriptionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getFormatOptions = (type) => {
    if(type == "image"){
      return this.setImageOptions()
    }else if(type == "video"){
      return this.setVideoOptions()
    }
  }
  setImageOptions = () => {
    return (
    <div>
        <CustomTypography>Select {this.state.catalogueType} format</CustomTypography>
        {this.state.imageFormats?.map((size) => (
          <div key={size}>
            <FormControlLabel
              style={{
                ...webStyle.clickableLabel,
                ...(this.state.selectedValue === size.id ? webStyle.selectedLabel : webStyle.clickableLabel),
              }}              
              value={size.id}
              control={
                <Radio
                  style={{
                    color: this.state.selectedValue === size.id && customTheme.palette.white.main,
                  }}
                />
              }
              labelPlacement="start"
              label={
                <Typography
                  style={{
                    fontSize: 16,
                    fontWeight: this.state.selectedValue === size.id && "bold",
                    color: this.state.selectedValue !== size.id && "grey",
                  }}
                >
                  {size.attributes.name.toUpperCase()} SIZE
                </Typography>
              }
              testId="radioImgBtn"
              checked={this.state.selectedValue === size.id}
              onChange={(event)=> this.setFormatPrice(event.target.value)}
              />

            {this.state.selectedValue === size.id && (
              <Grid
                container
                style={webStyle.radioDiv}
              >

                <Typography style={{ display: "flex", color: "grey" }}>
                {this.state.productData?.attributes?.height + (this.state.productData?.attributes?.height * size.attributes.percentage / 100)} X {this.state.productData?.attributes?.height + (this.state.productData?.attributes?.width * size.attributes.percentage / 100)} px
                  <Divider
                    orientation="vertical"
                    style={webStyle.radioDivision}
                  />
                  {this.state.productData?.attributes?.height_in_inches + (this.state.productData?.attributes?.height_in_inches * size.attributes.percentage / 100)} X {this.state.productData?.attributes?.width_in_inches + (this.state.productData?.attributes?.width_in_inches * size.attributes.percentage / 100) } in
                  <Divider
                    orientation="vertical"
                    style={webStyle.radioDivision}
                  />
                  {this.state.productData?.attributes?.dpi + (this.state.productData?.attributes?.dpi * size.attributes.percentage / 100)} DPI
                  <Divider
                    orientation="vertical"
                    style={webStyle.radioDivision}
                  />
                   {this.state.productData?.attributes?.images.type}
                  <Divider
                    orientation="vertical"
                    style={webStyle.radioDivision}
                  />
                  {this.state.productData?.attributes?.image_size_in_mb + (this.state.productData?.attributes?.image_size_in_mb * size.attributes.percentage / 100)} MB
                 </Typography>
              </Grid>
            )}
          </div>
        ))}
      </div>
    )
  }
  setVideoOptions = () => {
    return (
      <div>
      <CustomTypography>Select {this.state.catalogueType} format</CustomTypography>
      <Grid container item xs={12}>
              {(this.state.videoFormats)?.map(item => (
                <Grid item xs={4} key={item.id}>
                  <div testId="videoSelection" style={{ ...webStyle.resDiv, borderColor: this.state.selectedRes === item.id ? "#3A82FF" : "lightgray" }}
                    onClick={() => this.setFormatPrice(item.id) }>
                    <div style={{ ...webStyle.titleDiv, backgroundColor: this.state.selectedRes === item.id ? "#3A82FF" : "lightgray" }}>
                      <Typography style={{ padding: 10, color: this.state.selectedRes === item.id ? "white" : "black" }}>{item.attributes.name}</Typography>
                    </div>
                    <div style={{ textAlign: "center", padding: 15, position: "relative", width: "100%", boxSizing: "border-box" }}>
                    <CustomTypography variant={'primary'} component={'body4'} textTransform={'none'}>{item.attributes.resname}</CustomTypography>
                    <CustomTypography variant={'secondary'} component={'body4'} textTransform={'none'}>({item.attributes.dimention})</CustomTypography>
                      {this.state.selectedRes === item.id &&
                        <img style={webStyle.blueTickDiv}
                          src={require("../assets/blueTick.png")}
                          alt="Watermark"
                        />
                      }
                    </div>
                  </div>
                </Grid>
              ))}
      </Grid>
      </div>
    )
  }
  requestForm = () => {
    return (
      <div>
      <Grid container>
          <Grid item style={webStyle.marginBtm20}>
          <CustomTypography variant={'primary'} component={'body3'} textTransform={'none'}>This license needs to be quoted</CustomTypography>
          <CustomTypography variant={'secondary'} component={'body3'} textTransform={'none'}>Expected to be delivered within <span style={{color:customTheme.palette.primary.main}}>1 business day.</span></CustomTypography>
          </Grid>
          <Grid item style={webStyle.marginTop10}>
          <InputComponent 
          value={this.state.email}
          onChange={this.handleChange}
          type='text'
          name='email'
          required
          placeholder="Email address"
          ></InputComponent>
          <InputComponent 
          value={this.state.phone}
          onChange={this.handleChange}
          type='text'
          name='phone'
          required
          placeholder="Phone number"
          ></InputComponent>
          <InputComponent 
          value={this.state.endClient}
          onChange={this.handleChange}
          type='text'
          name='endClient'
          required
          placeholder="End client"          
          ></InputComponent>
          <InputComponent 
          value={this.state.distDetails}
          onChange={this.handleChange}
          type='text'
          name='distDetails'
          required
          placeholder="Distribution details"
          ></InputComponent>
          </Grid>
      </Grid>
              <Button
                variant="contained"
                color="default"
                testId="requestBtn"
                onClick={()=> this.postSendRequest()}
                style={webStyle.requestBtn}
               > SUBMIT REQUEST
               </Button>
          </div>
    )
  }
  downloadImage() {
    // Logic to download the image
    const imageUrl = `data:image/png;base64,${this.state.productData?.attributes?.water_mark_image}`;
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'image.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
      <Header navigation={this.props.navigation} testID={""} classNameProps={undefined} />
      <Container maxWidth={"xl"} style={{paddingBottom:20}}>
      <Grid container style={webStyle.gridContainer}>
          <Grid item xs={12} sm={6} style={webStyle.imageGrid}>
            {this.state.catalogueType == 'video'?
               <video style={webStyle.mediaDimension} controls>
               <source src={this.state.productData?.attributes?.images.url} type={this.state.productData?.attributes?.images.type} />
                 Your browser does not support the video tag.
               </video> 
            :
              <div style={{ position: 'relative', display:'flex',justifyContent:'center',alignItems:'center' }}>   
               <img style={webStyle.mediaDimension} src={`data:image/png;base64,${this.state.productData?.attributes?.water_mark_image}`} alt="Product image"></img>
               <div style={webStyle.absoluteTopDiv}>
               <CropFreeSharpIcon style={webStyle.whiteColor}/>
               </div>
               <Box style={webStyle.absoluteBottomDiv}>
               <Box style={{...webStyle.absoluteBottomImage,marginRight:10}}>
                {this.state.productData?.attributes?.bookmark == "0" ?
               <BookmarkBorderOutlinedIcon onClick={()=>this.postBookmarkAPI()} style={webStyle.whiteColor}/>
                :
                <BookmarkOutlinedIcon testId="bookmarkBtn" onClick={()=>this.postBookmarkAPI()} style={webStyle.whiteColor}/>
                }
               </Box>
               <Box style={{...webStyle.absoluteBottomImage}}>
               <GetAppIcon testId="downloadBtn" onClick={()=>this.downloadImage()} style={webStyle.whiteColor}/>
               </Box>
               </Box>
              </div> 
            }
            
          </Grid>
          <Grid item xs={12} sm={6} style={webStyle.cartGrid}>
            <CustomTypography>PURCHASE LICENSE</CustomTypography>
            <Divider style={webStyle.dividerStyle}></Divider>
            <CustomTypography>Select your license type to get personalised pricing.</CustomTypography>
            <ThemeProvider theme={themeDropdown}>
            <FormControl variant="outlined" style={webStyle.sizeDropdownStyle}>
              <InputLabel>How are you distributing this?</InputLabel>
              <Select
                id="demo-simple-select-outlined"
                label="How are you distributing this?"
                multiple
                testId="sizeDropdown"
                onChange={(event) => {this.checkIsCommercial(event.target.value) }}
                renderValue={(selected) => selected.join(", ")}
                value={this.state.electronicData}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  ...MenuProps,
                }}
              >
                <MenuItem value="" disabled>
                  <em>Select all that apply</em>
                </MenuItem>
                {this.state.licenseTypes?.map((license) => (
                    <MenuItem key={license.id} value={license.attributes.name}>
                      <Checkbox
                        checked={this.state.electronicData.indexOf(license.attributes.name) > -1}
                      />
                      <ListItemText primary={license.attributes.name} />
                    </MenuItem>
                ))}
              </Select>
            </FormControl>
            </ThemeProvider>
            <ThemeProvider theme={themeDropdown}>
            <FormControl variant="outlined" style={{ width: "100%", marginTop: 20 ,marginBottom: 20}}>
              <InputLabel>How large is your end client?</InputLabel>
              <Select
                id="demo-simple-select-outlined"
                label="How are you distributing this?"
                testId="clientDropdown"
                onChange={(event) => this.setSizePriceAndId(event.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  ...MenuProps,
                }}
              >
                <MenuItem value="" disabled>
                  <em>How large is your end client?</em>
                </MenuItem>
                {this.state.clientSizes?.map((size) => (
                    <MenuItem key={size.id} name={size.id} value={size.id}>
                      <ListItemText primary={size.attributes.name}/>
                    </MenuItem>
                ))}
              </Select>
            </FormControl>
            </ThemeProvider>

            <div>
            <FormControl style={{ width: "100%" }}>
            {!this.state.isRqeustForm && this.getFormatOptions(this.state.catalogueType)}
            {this.state.isRqeustForm && this.requestForm()}
            </FormControl>
            </div>

            {!this.state.isRqeustForm &&
            <div>
            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center",marginTop:20,marginBottom:20}}>
            <CustomTypography>Total price</CustomTypography>
            <CustomTypography variant={'primary'} component={'h4'}>{this.state.totalPrice} AED</CustomTypography>
            </div>
            <CustomButton 
            onClick={() => {this.setState({cartModalFlag:true}); this.postAddToCart()}}
            variant='primary'
            fullWidth='fullWidth'
            size={'large'}
            testId='btnAddCart'
          >
          <img style={{ width:20, marginRight:10 }} src={basket} alt="basket"/>  ADD TO BASKET
          </CustomButton>
          </div>
            }
          </Grid>
      </Grid>

      <Grid container style={webStyle.directionColumn}>
        <CustomTypography textTransform={'none'}>{this.state.productData?.attributes?.title}</CustomTypography>
            <Grid xs={12} sm={7} item style={webStyle.userGridStyle}>
              <div style={{display:"flex"}}>
              <Avatar style={{marginRight:10}} alt={this.state.productData?.attributes?.account} src={this.state.productData?.attributes?.account_image} />
              <div>
              <CustomTypography textTransform={'none'}>{this.state.productData?.attributes?.account}</CustomTypography>
              <CustomTypography 
              variant={'secondary'}
              component={'body3'} 
              textTransform={'none'}>
              {this.state.productData?.attributes?.follower_count} Followers
              </CustomTypography>
              </div>
              <Button
                variant="contained"
                color="default"
                testId="followBtn"
                onClick={()=>this.postFollowAPI()}
                style={{...webStyle.actionBtn,marginLeft:25}}
                startIcon={<img style={{width:20}} src={require("../assets/follow.png")}  alt={"item.title"} />}
               > {this.state.productData?.attributes?.follow == "0" ? "FOLLOW" : "FOLLOWING"}
               </Button>
              </div>
              <div>
              <Button
                variant="contained"
                color="default"
                testId="likeBtn"
                onClick={()=>this.postLikeAPI()}
                style={webStyle.actionBtn}
                startIcon={<FavoriteBorderIcon />}
               > {this.state.productData?.attributes?.favourties == "0" ? "LIKE" : "LIKED"}
               </Button>
               <Button
                variant="contained"
                color="default"
                style={webStyle.actionBtn}
                startIcon={<ShareIcon />}
               > SHARE
               </Button>
              </div>
            </Grid>
            <Grid container style={{margin:"20px 0px"}}>
            <div style={webStyle.specsDiv}>
              <CustomTypography variant={'secondary'} component={'body4'} textTransform={'none'}>{this.state.productData?.attributes?.aspect_ratio}</CustomTypography>
              </div>
              <div style={webStyle.specsDiv}>
              <CustomTypography variant={'secondary'} component={'body4'} textTransform={'none'}>{this.state.productData?.attributes?.height} X {this.state.productData?.attributes?.width}</CustomTypography>
              </div>
              <div style={webStyle.specsDiv}>
              <CustomTypography variant={'secondary'} component={'body4'}  textTransform={'none'}>Stock image id: {this.state.productData?.id}</CustomTypography>
              </div>
            </Grid>
            </Grid>
            <Grid container style={webStyle.directionColumn}>
              <Grid item>
            <FlatList
              testId="keywordList"
              contentContainerStyle={webStyle.keywordFlatListStyle}
              data={this.state.productData?.attributes?.keyword_array}
              renderItem={({item,index})=>(
              <div style={webStyle.hashTags}>
              <CustomTypography variant={'secondary'} component={'body5'} textTransform={'none'}>#{item}</CustomTypography>
              </div>
              )}
              // keyExtractor={}
            />
            </Grid>
            <Grid item style={{flexGrow:0}}>
            <CustomTypography variant={'secondary'} component={'body3'} textTransform={'none'}>Uploaded on {this.state.productData?.attributes?.updated_at}</CustomTypography>
            </Grid>
        </Grid>
        <Grid container style={webStyle.marginTop50}>
              <Grid item xs={12}>
              <CustomTypography variant={'primary'} component={'body3'}>COMMENTS</CustomTypography>
              <Divider style={webStyle.dividerStyle}></Divider>
              <div style={{display:"flex", alignItems:"center"}}>
              <Avatar style={webStyle.avatarStyle} alt="Remy Sharp" src={require('../assets/profile.png')} />
              <CustomTypography variant={'secondary'} component={'para'} textTransform={'none'}>Add your comment</CustomTypography>
              </div>
              {this.state.commentsData?.map((comment, index) => (
                  <div key={index} style={webStyle.commentMainDiv}>
                    <div style={{ display: "flex", flex: 0.25 }}>
                      <Avatar style={webStyle.avatarStyle} alt={comment.attributes.account_details} src={comment.attributes.account_image} />
                    </div>
                    <div style={webStyle.commentSecondDiv}>
                    <CustomTypography variant={'primary'} component={'body3'}>{comment.attributes.account_details}
                        <span style={{ fontSize: 13, color: "gray" }}> . {comment.attributes.updated_at}</span></CustomTypography>
                      <CustomTypography variant={'secondary'} component={'body3'}>{comment.attributes.comment}</CustomTypography>
                      <CustomTypography variant={'primary'} component={'body3'}>REPLY</CustomTypography>
                    </div>
                  </div>
               ))}
              <CustomTypography variant={'primary'} component={'body3'}>VIEW ALL COMMENTS</CustomTypography>
              </Grid>
        </Grid>
        <Grid container style={webStyle.marginTop50}>
              <Grid item xs={12}>
              <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginBottom:20}}>
              <div style={{display:"flex", marginTop:20, alignItems:"center"}}>
              <Avatar style={{marginRight:15, height:30, width:30}} alt={this.state.productData?.attributes?.account} src={this.state.productData?.attributes?.account_image}/>
              <CustomTypography variant={'primary'} component={'body3'}>{this.state.productData?.attributes?.account}</CustomTypography>
              </div>
              <div>
              <CustomTypography variant={'primary'} component={'body3'} textTransform={'underline'}>VIEW ALL</CustomTypography>
              </div>
              </div>
              <Divider></Divider>
              <ImageList rowHeight={170} style={webStyle.marginTop20} cols={4}>
                  {(this.state.userscatalogueImages)?.map((catImage) => (
                    <ImageListItem style={webStyle.marginBtm10} cols={1}>
                      {catImage.attributes.images.type.includes('video')?
                        <video style={webStyle.mediaDimension} controls>
                        <source src={catImage.attributes.images.url} type={catImage.attributes.images.type} />
                          Your browser does not support the video tag.
                        </video> 
                      :
                        <img style={{width:"98%"}} src={catImage.attributes.images.url}  alt={catImage.id} />
                      }
                    </ImageListItem>
                  ))}
              </ImageList>
              </Grid>
        </Grid>
        <Grid container style={webStyle.marginTop50}>
              <Grid item xs={12}>
              <CustomTypography variant={'primary'} component={'body3'} textTransform={'none'}>SIMILAR IMAGES</CustomTypography>
              <Divider style={webStyle.dividerStyle}></Divider>
              <ImageList rowHeight={170} cols={4}>
                  {this.state.similarImages?.map((image) => (
                    <ImageListItem style={webStyle.marginBtm10} cols={1}>
                      <img style={{width:"98%"}} src={image.url}  alt={image.catalogue_id} />
                    </ImageListItem>
                  ))}
              </ImageList>
              </Grid>
        </Grid>
        <ModalComponent
            open={this.state.cartModalFlag}
            maxWidth={600}
            maxHeight={460}
            >
              <CustomTypography variant={'primary'} component={'body4'} textTransform={'none'}>ADDED TO CART</CustomTypography>
              <Divider style={webStyle.dividerStyle}></Divider>
              <div style={webStyle.cartModalDiv}>

                <div style={{display:"flex", flex:1}}>
                <img style={{width:"90%", height:120}} src={this.state.productData?.attributes?.images.url}  alt={"Product image"} />
                </div>

                <div style={webStyle.cartDivStyle}>
                <div style={{display:"flex", flexDirection:"row" }}>
                <div style={{display:"flex", flex:1.3 }}>
                <CustomTypography variant={'primary'} component={'body4'} textTransform={'none'}>{this.state.productData?.attributes?.title}</CustomTypography>
                </div>
                <div style={webStyle.currencyDiv}>
                <CustomTypography variant={'primary'} component={'body3'} textTransform={'none'}>{this.state.totalPrice} AED</CustomTypography>
                </div>
                </div>
                <div style={{display:"flex"}}>
                <div style={webStyle.specsDiv}>
                <CustomTypography variant={'secondary'} component={'body4'} textTransform={'none'}>{this.state.productData?.attributes?.aspect_ratio} Landscape</CustomTypography>
              </div>
              <div style={webStyle.specsDiv}>
              <CustomTypography variant={'secondary'} component={'body4'} textTransform={'none'}>{this.state.productData?.attributes?.height} X {this.state.productData?.attributes?.width}</CustomTypography>
              </div>
              <div style={webStyle.specsDiv}>
              <CustomTypography variant={'secondary'} component={'body4'} textTransform={'none'}>Digital</CustomTypography>
              </div>
              </div>
                </div>
              </div>
              <Divider></Divider>
              <div style={webStyle.btnNavigation}>
              <div style={{width:"100%"}}>
                        <CustomButton
                          variant="secondary"
                          fullWidth="fullWidth"
                          size="medium"
                          testId="goToHomePage"
                          onClick={()=>this.gotoHomePage()}
                        >
                          CONTINUE SHOPPING
                        </CustomButton>
                        </div>
                        <div style={{width:"100%"}}>
                        <CustomButton variant="primary" fullWidth="fullWidth" size="medium" 
                        // onClick={() => { this.handleSave() }} 
                        data-test-id="savebtn">
                          GO TO CART
                        </CustomButton>
                        </div>
                        </div>
                        </ModalComponent>
      </Container>
      <Footer 
          userType={'normal'}
          navigation={this.props.navigation}
          toNavigateGeneric={this.toNavigateGeneric}
          data-test-id="toNavigateGeneric"
      />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
 gridContainer:{
  boxSizing: "border-box"
 },
 imageGrid:{
  padding:50
 },
 cartGrid:{
  padding:50
 },
 marginTop10:{ marginTop: 10},
 marginTop20:{ marginTop: 20},
 marginTop50:{ marginTop: 50},
 marginBtm10:{ marginBottom: 10},
 marginBtm20:{ marginBottom: 20},
 cartModalDiv: {display:"flex", flex:3, marginBottom:30},
 cartDivStyle: {display:"flex", flex:2, flexDirection:"column", justifyContent:"space-between" },
 currencyDiv: {display:"flex", flex:0.7, justifyContent:"center" },
 btnNavigation: {display:"flex", gap:10, marginTop:20},
 userGridStyle: {display:"flex", justifyContent:"space-between",marginTop:20},
 keywordFlatListStyle: {flexDirection:"row", flexWrap:"wrap"},
 avatarStyle: {marginRight:15, height:30, width:30},
 commentMainDiv: { display: "flex", flex: 12, marginTop: 20 },
 commentSecondDiv: { display: "flex", flex: 11.75, flexDirection: "column" },
 directionColumn: {flexDirection:"column"},
 sizeDropdownStyle: { width: "100%", marginTop: 20 },
 actionBtn: {
  borderRadius: 0,
  color: "#73767A",
  boxShadow: "none",
  marginRight:10
},
specsDiv:{
  backgroundColor:"#d5d5d5", 
  padding:"4px 16px",
  marginRight: 10,
  marginBottom: 10,
  marginTop: 10
},
hashTags:{
  backgroundColor:"#d5d5d5", 
  padding:"6px 16px",
  marginRight: 10,
  marginBottom: 10
},
absoluteTopDiv:{
  position:"absolute",
  width:"10%",
  padding: 10,
  backgroundColor: 'rgba(0, 0, 0, .2)',
  top: "2%",
  height: "auto",
  boxSizing: "border-box", 
  justifyContent: "center",
  display: "flex",
  marginLeft: "65%"
},
absoluteBottomDiv:{
  position:"absolute",
  bottom: "2%",
  paddingLeft: "59%",
  display:"flex",
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
},
absoluteBottomImage:{
  padding: 10,
  backgroundColor: 'rgba(0, 0, 0, .2)',
  height: "auto",
  boxSizing: "border-box", 
  justifyContent: "center",
  display: "flex"
},
clickableLabel: {
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  padding: "6px 12px",
  border: "1px solid lightgray",
  borderColor: "lightgray",
  margin: 0,
  marginTop: 10,
  justifyContent: "space-between"
},
dividerStyle:{
  marginBottom: 20,
  marginTop: 15
},
selectedLabel: {
  backgroundColor: "#3A82FF",
  color: "#fff",
  borderColor: "#3A82FF"
},
resDiv: {
  border: "2px solid", borderColor: "lightgray", alignItems: "center", display: "flex", flexDirection: "column", width: "90%", marginBottom:10
},
titleDiv: { backgroundColor: "#E7EBEB", width: "100%", textAlign: "center" },
requestBtn:{
  borderRadius: '0px',
  width: '100%',
  marginTop: '20px',
  boxShadow: 'none',
  backgroundColor: '#3A82FF',
  color: 'white'
},
radioDiv:{
  border: `2px solid ${ "#3A82FF" }`,
  borderTop: "0px",
  padding: 10,
  marginBottom: 10,
},
radioDivision:{ margin: "0 10px", backgroundColor: "grey" },
blueTickDiv: { position: "absolute", bottom: 0, right: 0, width: 40 },
mediaDimension: { width: '80%',height:'65%' },
whiteColor: {color:customTheme.palette.white.main}
};
// Customizable Area End
